/****************************************************************
 * File : index.js
 * Description :-
 * 1. Entry point of User Registration React Application
 * 2. Attach Store (used for state management) to React app using
 *    Provider. - React - Redux Architecture
 * 3. Store is imported from ./store.js file
 * @author Gauri Yadav
 ****************************************************************/
// These must be the first lines in src/index.js | Start
// These must be the first lines in src/index.js | End
import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//React - Redux
import store from './store'
import { Provider } from 'react-redux'

render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
