import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import AccountCreationComponent from "./components/AccountCreationComponent";
import AccountSetUpComponent from "./components/AccountSetUpComponent";
import AccountSubmissionComponent from './components/AccountSubmissionComponent';
import ChangePasswordComponent from './components/ChangePasswordComponent';
import CloudUserCreationComponent from './components/CloudUserCreationComponent';
import EncryptionAgreementComponent from "./components/EncryptionAgreementComponent";
import ErrorPageComponent from './components/ErrorPageComponent';
import EvaluationUserComponent from './components/EvaluationUserComponent';
import HomeComponent from './components/HomeComponent';
import LabUserComponent from './components/LabUserComponent';
import LoginAssistanceComponent from "./components/LoginAssistanceComponent";
import ResetPasswordComponent from './components/ResetPasswordComponent';
import './style/css/accountCreation.css';
import './style/css/accountSubmission.css';
import './style/css/changePwd.css';
import './style/css/loginAssistance.css';
import './style/css/resetPwd.css';
import './style/fonts/font-awesome/css/all.css';
import './style/scss/tooltip.scss';

class App extends Component {
    render() {
      const embeddedServiceHelpButton = document.getElementsByClassName("embeddedServiceHelpButton")
      if(window.location.pathname !== "/") {
        if(embeddedServiceHelpButton.style) embeddedServiceHelpButton.style.display = "none"
      } else {
        if(embeddedServiceHelpButton.style) embeddedServiceHelpButton.style.display = "block"
      }

        return (
            <Router>
            <div>
              <Switch>
                <Route exact path="/" component={HomeComponent} />
                <Route exact path="/validateRegistration" component={AccountSetUpComponent} />
                <Route exact path={["/editaccountinformation", "/accountinformation"]} component={AccountCreationComponent} />
                <Route exact path={["/acceptencryptionagreement", "/encryptionagreement"]} component={EncryptionAgreementComponent}/>
                <Route exact path="/loginassistance" component={LoginAssistanceComponent} />
                <Route exact path={["/changepassword","/changePasswordWithUnlockKey"]} component={ChangePasswordComponent}/>
                <Route exact path="/resetpassword" component={ResetPasswordComponent}/>
                <Route exact path="/accountsubmission" component={AccountSubmissionComponent}/>
                <Route exact path="/cloudusercreation" component={CloudUserCreationComponent}/>
                <Route exact path={"/errorpage"} component={ErrorPageComponent}></Route>
                <Route exact path={"/labuser"} component={LabUserComponent}></Route>
                <Route exact path={"/evaluationuser"} component={EvaluationUserComponent}></Route>
              </Switch>  
            </div>
          </Router>
        );
    }
}
export default App;
