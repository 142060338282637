import React, { Component } from "react"
import FooterHyperlinksComponent from "./FooterHyperlinksComponent"
import ReactTooltip from 'react-tooltip'
import { isEnglishChar } from '../utils'
import LoadingOverlay from 'react-loading-overlay';
//Redux
import { fetchCloudProvider , fetchCloudRegionsAndProducts, validateCloudData, clearValidateData} from '../actions/cloudAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

let applicationData = {};
var cloudProviderObj = {};
class CloudUserCreationComponent extends Component {
    constructor(props) {
        super();
        this.state = {
            cloudInstanceId: "",
            cloudAccId: "",
            cloudMktPlace: -1,
            subsId: "",
            productTitle: -1,
            region: -1,
            toggleCloudProviderDetails : true,
            toggleAWSField : true,
            errorproductTitle: false,
            errorregion : false,
            errorcloudAccId : false,
            errorsubsId : false,
            enableNextButton : false,
            errorcloudInstanceId: false,
            cloudLabel : "",
            errorNonEngsubsId : false,
            errorNonEngcloudAccId : false,
            errorNonEngcloudInstanceId : false,
            errorInvalidAcctId : false
        }
        this.navigateToAccountSetUp = this.navigateToAccountSetUp.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onClickOfNextButton = this.onClickOfNextButton.bind(this);
        this.removeAllErrorMessages = this.removeAllErrorMessages.bind(this);
        this.renderCloudProviderList = this.renderCloudProviderList.bind(this);
        this.persistDataAndNavigateToAccCreation = this.persistDataAndNavigateToAccCreation.bind(this);
        this.renderCloudProducts = this.renderCloudProducts.bind(this);
        this.renderCloudRegions = this.renderCloudRegions.bind(this);
        this.errorRef = React.createRef();
    }
    componentDidMount = () => {
        applicationData = this.props.location.state.applicationData;
        window.scrollTo(0,0);
        this.props.fetchCloudProvider('/getCloudProviders');
    }

    componentDidUpdate(prevProps){
        if(prevProps.validateCloudDataAcctId !== this.props.validateCloudDataAcctId){
            let node = this.errorRef.current;
            if(node !== null && this.props.validateCloudDataAcctId.ErrorMessage !== 'SUCCESS'){
                node.innerHTML = this.props.validateCloudDataAcctId.ErrorMessage
            }else if(this.props.validateCloudDataAcctId.ErrorMessage === "SUCCESS"){
                applicationData.cloudProviderObj = cloudProviderObj;
                        this.props.history.push({
                            pathname: '/accountinformation',
                            state: { applicationData : applicationData }
                          });
            }
        }
    }
    /*****************************************************************
    * Triggered : On change of any Input form field 
    * Description : This function is used to update the field
    *               value and instantly remove the error associated with
    *               that field.
    ******************************************************************/
    onChangeField(event) {
        this.props.clearValidateData();
        let cloudDetailUrl = "/getCloudDetails";
        this.setState({ [event.target.name]: event.target.value });
        var errorString = "error" + event.target.name;
        if (event.target.value.trim() === "" || event.target.value === "-1" || event.target.value === -1) {
            this.setState({ [errorString]: true }, () => {
                this.onClickOfNextButton();
            });
        } else {
            this.setState({ [errorString]: false }, () => {
                this.onClickOfNextButton();
            });
        }
        if(event.target.type === "select-one" && event.target.name === "cloudMktPlace"){
            if(event.target.value === "AWS"){
                //Invoke /getCloudDetails to populate Cloud regions and products 
                let cloudDetailPayLoad = {
                    "cloudProviderId": "AWS"
                }
                this.props.fetchCloudRegionsAndProducts(cloudDetailUrl,cloudDetailPayLoad);
                this.setState({toggleCloudProviderDetails : false},() => {
                    this.onClickOfNextButton();
                });
                this.setState({toggleAWSField : true},() => {
                    this.onClickOfNextButton();
                });
                this.removeAllErrorMessages();
                this.setState({cloudLabel : "AWS"},() => {
                    this.onClickOfNextButton();
                });
            }else if(event.target.value === "MSAZURE"){
                 //Invoke /getCloudDetails to populate Cloud regions and products 
                 let cloudDetailPayLoad = {
                    "cloudProviderId": "MSAZURE"
                }
                this.props.fetchCloudRegionsAndProducts(cloudDetailUrl,cloudDetailPayLoad);

                this.setState({toggleCloudProviderDetails : false});
                this.setState({toggleAWSField : false});
                this.removeAllErrorMessages();
                this.setState({cloudLabel : "MS Azure"});
            }else if(event.target.value === "GCP"){
                 //Invoke /getCloudDetails to populate Cloud regions and products 
                 let cloudDetailPayLoad = {
                    "cloudProviderId": "GCP"
                }
                this.props.fetchCloudRegionsAndProducts(cloudDetailUrl,cloudDetailPayLoad);

                this.setState({toggleCloudProviderDetails : false});
                this.setState({toggleAWSField : true});
                this.removeAllErrorMessages();
                this.setState({cloudLabel : "GCP"});
            }else{
                this.setState({toggleCloudProviderDetails : true});
                this.setState({enableNextButton : false});
                this.removeAllErrorMessages();
            }
 
        }
        //checking for Non-English characters
        this.checkForEngChar(event);
    }
 
    /***************************************************************
     * Triggered : On change of cloud market place dropdown value
     * Description : This function is used to remove all pre-existing
     *               error messages.
     *
     *************************************************************/
    removeAllErrorMessages(){
        
        this.setState({cloudInstanceId: "",
        cloudAccId: "",
        subsId: "",
        productTitle: -1,
        region: -1})
        this.setState({errorproductTitle : false});
        this.setState({errorregion : false});
        this.setState({errorcloudAccId : false});
        this.setState({errorsubsId : false});
        this.setState({errorcloudInstanceId : false});
        this.setState({errorNonEngcloudAccId : false})
        this.setState({errorNonEngcloudInstanceId : false})
        this.setState({errorNonEngsubsId : false})
    }
 
    /**********************************************************
     * Triggered : On click of Back button
     * Description : This function is used to navigate back to
     *               Account Set Up page.
     *
     *********************************************************/
    navigateToAccountSetUp() {
        var applicationData = this.props.location.state.applicationData;
        applicationData.isNavigatedBackFromAcctCreation = "true";
        this.props.history.push({
            pathname: '/',
            state: { applicationData : applicationData },
            prevPath: this.props.location.pathname
          })
    }
 
    //This function is responsible for toggling of next button | diabling | enabling
    onClickOfNextButton(){
        var allMandatoryFieldsFilled = true;
        if(this.state.toggleAWSField && (this.state.errorcloudAccId || this.state.errorcloudInstanceId || this.state.errorproductTitle || 
            this.state.errorregion || this.state.cloudInstanceId === "" || this.state.cloudAccId === ""
            || this.state.productTitle === -1 || this.state.productTitle === "-1" || this.state.region === -1 || 
            this.state.region === "-1" || this.state.errorNonEngcloudAccId || this.state.errorNonEngcloudInstanceId)){
                allMandatoryFieldsFilled = false;
            }
            if(!this.state.toggleAWSField && (this.state.subsId.trim() === "" || this.state.errorsubsId || this.state.errorproductTitle || this.state.errorregion || 
            this.state.productTitle === -1 || this.state.productTitle === "-1" || this.state.region === -1 || 
            this.state.region === "-1" || this.state.errorNonEngsubsId)){
                allMandatoryFieldsFilled = false;
            }

        if(allMandatoryFieldsFilled)
        {
            this.setState({
                enableNextButton : true
            })
        }else{
            this.setState({
                enableNextButton : false
            })
        }
    }

    /*****************************************************************
     * Triggered : On blur of any Input form field
     * Description : This function is used to validate whether input provided
     *               is in english or not.
     ******************************************************************/
    checkForEngChar(event) {
        var isFieldContainsEnglishCharacter = isEnglishChar(event);
        var errorString = "errorNonEng" + event.target.name;
        if (!isFieldContainsEnglishCharacter) {
            this.setState({ [errorString]: true });
        } else {
            this.setState({ [errorString]: false });
        }
    }

    //This function is used to populate cloud provider dropdown w.t.h of API response fetched as redux props
    renderCloudProviderList(cloudProviderList){
        return cloudProviderList.map((cloudProvider,index)=>{
			return <option key={"cloudProvider"+index} value={cloudProvider.cloudProviderId} >{cloudProvider.cloudProviderName}</option>
		})
    }
    
    //This function is used to persist cloud data and later is utilised in encry agreement component to make fianl payload
    persistDataAndNavigateToAccCreation(){
       
        cloudProviderObj.cloudProvider = this.state.cloudMktPlace;
        cloudProviderObj.cloudProductTitle = this.state.productTitle;
        cloudProviderObj.cloudInstanceId = this.state.toggleAWSField ? this.state.cloudInstanceId : null;
        cloudProviderObj.cloudRegion = this.state.region;
        cloudProviderObj.cloudAccId = this.state.toggleAWSField ? this.state.cloudAccId : this.state.subsId;
        var validateCloudDataReqPayload = {
            "cloudProviderId" : this.state.cloudMktPlace,
            "cloudInstanceId" : this.state.toggleAWSField ? this.state.cloudInstanceId : null,
            "cloudProductCode" : this.state.productTitle,
            "cloudRegionId" : this.state.region,
            "cloudAccountId" : this.state.toggleAWSField ? this.state.cloudAccId : this.state.subsId,
        }
        this.props.validateCloudData('/validateCloudInfo',validateCloudDataReqPayload);
    }
 
    //This function is to render Product Title dropdown using API response
    renderCloudProducts(cloudProducts){
        if(cloudProducts !== undefined) {
            return cloudProducts.map((cloudProduct, index) => {
                return <option key={"cloudProduct" + index} value={cloudProduct.cloudProductCode} >{cloudProduct.cloudProductDesc}</option>
            })
        }
    }
    //This function is to render Regions dropdown using API response
    renderCloudRegions(cloudRegions){
        if(cloudRegions !== undefined) {
            return cloudRegions.map((cloudRegion, index) => {
                return <option key={"cloudRegion" + index} value={cloudRegion.cloudRegionId} >{cloudRegion.cloudRegionName}</option>
            })
        }
    }

    render() {
        let {cloudProviderList, cloudDetail, validateCloudDataLoader} = this.props;
        return (
            <div>
                <LoadingOverlay
                    active={validateCloudDataLoader}
                    spinner
                    text='Validating Cloud User...'
                >
                <div className="grid padding-around font-size-medium center-element width-80">
                <ReactTooltip effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                    <div className="col-12">
                        <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p>
                        <p className="account-creation-n"><strong>Cloud Provider Information</strong></p><br />
                        <p>Select your Cloud Marketplace or Cloud Security Service Provider</p>
                        {this.props.validateCloudDataAcctId.ErrorMessage !== undefined && this.props.validateCloudDataAcctId.ErrorMessage !== 'SUCCESS'
                         && <p id = "error" ref={this.errorRef}></p>}
                        <br /><br />
                          <div className="col-9">
                            <div className="grid">
                                <p className="col-3"> <b>Cloud Marketplace </b><span className="text-required">*</span></p>
                                <div className="field-format col-5">
                                    <select className="no-margin-top" onChange={this.onChangeField} value={this.state.cloudMktPlace} name="cloudMktPlace" required>
                                        <option value="-1"> Select Cloud provider</option>
                                        {this.renderCloudProviderList(cloudProviderList)}
                                    </select>
                                </div>
                            </div>
                        </div >
                        { this.state.toggleCloudProviderDetails ? <div style={{ minHeight : 155, display : ""}}></div> :
                        <div>
                            <ReactTooltip effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                            <div className="col-9">
                                <div className="grid">
                                    <p className="col-3"> <b>{this.state.cloudLabel} Product Title </b><span className="text-required">*</span></p>
                                    <div className="field-format col-5">
                                        <select className="no-margin-top" onChange={this.onChangeField} value={this.state.productTitle} name="productTitle" required>
                                            <option value="-1">Select Juniper Product Code</option>
                                             {this.renderCloudProducts(cloudDetail.cloudProducts)}
                                        </select>
                                        {this.state.errorproductTitle && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Cloud Product Title!</p>}
                                    </div>
                                </div>
                            </div>
                            {this.state.toggleAWSField ? <div className="col-9">
                                <div className="grid">
                                <ReactTooltip effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                                    <p className="col-3"> <b>{this.state.cloudLabel} Instance Id </b><span className="text-required">*</span></p>
                                    <div className="col-5-5">
                                    <div className="field-format flex">
                                        <input type="text" placeholder="Type here..." name="cloudInstanceId" value={this.state.cloudInstanceId} onChange={this.onChangeField} required />
                                        <div><i className=" fa fa-question-circle fa-lg margin-left" data-tip={this.state.cloudLabel === "AWS" ? "Enter your Cloud Instance ID (This may be obtained from console)<br/>(Example i-1234567890abcjye1)" :
                                    "Enter your GCP Instance ID, this information may be obtained from your GCP management console (example 3636839099985049971)"}
                                        style={{ color: "#84B135",marginTop:"8px"}}></i></div>
                                    </div>
                                    {this.state.errorcloudInstanceId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Cloud Instance Id!</p>}
                                    {this.state.errorNonEngcloudInstanceId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>

                                </div>
                            </div> : <div></div>}
                            <div className="col-9">
                                <div className="grid">
                                    <p className="col-3"> <b>{this.state.cloudLabel} Region </b><span className="text-required">*</span></p>
                                    <div className="field-format col-5">
                                        <select className="no-margin-top" onChange={this.onChangeField} value={this.state.region} name="region" required>
                                            <option value="-1">Select Cloud Region</option>
                                            {this.renderCloudRegions(cloudDetail.cloudRegions)}
                                        </select>
                                        {this.state.errorregion && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Cloud Region!</p>}
                                    </div>
                                </div>
                            </div>
                            {this.state.toggleAWSField ?  <div className="col-9">
                            <ReactTooltip effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                                <div className="grid">
                                    <p className="col-3"> <b>{this.state.cloudLabel} {this.state.cloudLabel === "AWS" ? "Account Id" : "Project Number"} </b><span className="text-required">*</span></p>
                                    <div className="col-5-5">
                                    <div className="field-format flex">
                                        <input type="text" placeholder="Type here..." name="cloudAccId" value={this.state.cloudAccId} onChange={this.onChangeField} required />
                                        <div><i className=" fa fa-question-circle fa-lg margin-left" data-tip={this.state.cloudLabel === "AWS" ? "Enter your Cloud Account ID (This may be located on the Account Activity page)<br/>(Example 009912345678)"
                                    :"Enter your GCP Customer Project Number which may be obtained from the Dashboard page in the GCP console. (example 335156400566)"}
                                        style={{ color: "#84B135",marginTop:"8px"}}></i></div>
                                    </div>
                                    {this.state.errorcloudAccId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Cloud Account Id!</p>}
                                    {this.state.errorNonEngcloudAccId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div> : <div></div>}
                            {!this.state.toggleAWSField ? <div className="col-9">
                            <ReactTooltip effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                                <div className="grid">
                                    <p className="col-3"> <b>{this.state.cloudLabel} Subscription Id </b><span className="text-required">*</span></p>
                                    <div className="col-5-5">
                                    <div className="field-format flex">
                                    <input type="text" placeholder="Type here..." name="subsId" value={this.state.subsId} onChange={this.onChangeField} required />
                                       <div> <i className=" fa fa-question-circle fa-lg margin-left" data-tip="Enter your MS Azure Subscription ID which may be located in the MS Azure Portal <br/>(example fcec3fd2-e16f-1979-ae1b-060212jye1m5)."
                                        style={{ color: "#84B135",marginTop:"8px"}}></i></div>
                                    </div>
                                    {this.state.errorsubsId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Cloud Subscription Id!</p>}
                                    {this.state.errorNonEngsubsId && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div> : <div></div>}
        </div> }
                      
                        <div className="grid nav-buttons">
                                <div>
                                    <button onClick={() => this.navigateToAccountSetUp()} className="primary"><span className="back">&lt; Back </span></button>
                                </div>&nbsp; &nbsp;
                                <div>
                                    <button onClick={() => {this.persistDataAndNavigateToAccCreation()}} className={(this.state.enableNextButton && this.props.validateCloudDataAcctId.ErrorMessage === undefined )? "primary" : "primary disabled"}><span className="back">Next &gt; </span></button>
                                </div>
                            </div>  
                
                        <FooterHyperlinksComponent history={this.props.history}/>
                       
                    </div>
                </div>
                </LoadingOverlay>
            </div>
        )
    }
}
 

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state) {
    return {
        cloudProviderList: state.cloudReducer.cloudProviderList,
        cloudDetail : state.cloudReducer.cloudDetail,
        validateCloudDataAcctId : state.cloudReducer.validateCloudDataAcctId,
        validateCloudDataLoader : state.cloudReducer.validateCloudDataLoader
    }
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchCloudProvider,
    fetchCloudRegionsAndProducts,
    validateCloudData,
    clearValidateData
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(CloudUserCreationComponent)