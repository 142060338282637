
import React, {Component} from  'react'

class ErrorPageComponent extends Component {
    render(){
        return (
            <div className="body-container">
            <div className="col-md-12 error-text">
            <h1 style={{fontSize : '60px', textAlign : 'center', marginTop : '100px'}}>Oops!</h1>
            <h3 style={{fontSize : '30px', textAlign : 'center'}}>Something went wrong. Please try again in some time.</h3>
            <h4 style={{fontSize: '15px' ,textAlign : 'center'}}>If you are continuing to face issues with account creation, please  <a href="https://www.juniper.net/support/requesting-support.html" target="_blank" rel="noopener noreferrer">contact customer support</a> and we will be happy to assist you.</h4>
		</div>
            </div>
        )
    }

}

export default ErrorPageComponent