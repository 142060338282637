/***************************************************************************
 * File : accountsetupReducer.js
 * Description :-
 * 1. This file is used to update the state for items listed in Account set up page
 *     on the basis of action type and payload dispatched from accountsetupAction.js
 *     file.
 * @author Gauri Yadav
 **************************************************************************/
export const initalState = {
    countries: [],
    userType : null,
    isPublicDomain : "False",
    isEmailAlreadyExists : null,
    isRestrictedEmailDomains : null,
    loadingStarts : null,
    loadingSpinner : null,
    authCode : null,
    authInputLoading : null,
    serialNumLoading : false,
    serialNumErrMsg : null,
    elevateCircleCodeInputLoading: false,
    elevateValidateError: null,
    emailDomain: null,
    recaptchKeys: null,
    hideRadioButtons: false,
    submitSuccess: null
}

//Action constants
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const LOADING_ELEVATE_CIRCLE_CODE = 'LOADING_ELEVATE_CIRCLE_CODE'; 
export const LOAD_ELEVATE_CIRCLE_ERROR = "LOAD_ELEVATE_CIRCLE_ERROR";
export const LOAD_EMAIL_DOMAIN = "LOAD_EMAIL_DOMAIN";
export const LOAD_RECAPTCHA_CREDETAIL = "LOAD_RECAPTCHA_CREDETAIL";
export const HIDE_RADIO_BUTTONS = "HIDE_RADIO_BUTTONS";
export const LOAD_CREATE_ACCOUNT = "LOAD_CREATE_ACCOUNT";

/***************************************************************
 * accountsetupReducer :
 * @param {*} state 
 * @param {*} action 
 * @returns updated state
 * 
 * Actions:-
 * 1. GET_COUNTRIES : Fetch list of country from backend api /url
 ****************************************************************/
export const accountsetupReducer = (state = initalState, action) => {
    switch (action.type) {
          case GET_COUNTRIES:
                return Object.assign({}, state, {
                    countries: action.countries
                });
          case "GET_USER_TYPE":
                return {
                    ...state,
                    userType: action.userType
                }
         case "IS_PUBLIC_DOMAIN":
                return {
                    ...state,
                    isEmailPublicDomain: action.isEmailPublicDomain
                }
        case "IS_EMAIL_EXIST":
                return {
                    ...state,
                    isEmailAlreadyExists: action.isEmailAlreadyExists
                }
        case "IS_RESTRICTED_EMAIL_DOMAIN":
                return {
                    ...state,
                    isRestrictedEmailDomains: action.isRestrictedEmailDomains
                }
        case "LOADING":
                return {
                    ...state,
                    loadingStarts : action.loadingStarts,            
                }
        case "RESET_VALUES" : 
                return {
                    ...state,
                    userType : null,
                    isEmailPublicDomain : "False"
                }
        case "REMOVE_EMAIL_EXIST_ERROR":
                return {
                    ...state,
                    isEmailAlreadyExists: "False"
                }
        case "RESET_IS_RESTRICTED_EMAIL_DOMAIN":
                    return {
                        ...state,
                        isRestrictedEmailDomains: ""
                    }
        case "VALIDATE_AUTH_CODE":
                return {
                    ...state,
                    authCode : action.authCode
                }
        case   "AUTH_INPUT_LOADING" : 
                return{
                    ...state,
                    authInputLoading : action.authInputLoading
                }
        case "VALIDATE_SERIAL_NUM" :
                return{
                    ...state,
                    serialNumErrMsg : action.serialNumErrMsg,
                    serialNumRes : action.serialNumRes
                }
        case "SERIAL_NUM_LOADING" : 
                return{
                    ...state,
                    serialNumLoading : action.serialNumLoading
                }
        case "REMOVE_RADIO_ERRORS" : 
                return{
                    ...state,
                    authCode : "",
                    serialNumErrMsg : ""
                }
        case "RESET_SERIAL_NUM_MSG" : 
        return {
            ...state,
            serialNumErrMsg : ""
        }
        case "LOADING_SPINNER" : 
        return{
                ...state,
                loadingSpinner : action.loadingSpinner       
        }
        case "RESET_ELEVATE_AUTH_ERROR" :
            return{
                ...state,
                elevateValidateError : null
            }
        case LOADING_ELEVATE_CIRCLE_CODE:
            return { ...state, elevateCircleCodeInputLoading: action.data}
        case LOAD_ELEVATE_CIRCLE_ERROR:
            return { ...state, elevateValidateError: action.data }
        case LOAD_EMAIL_DOMAIN:
            return { ...state, emailDomain: action.data }
        case LOAD_RECAPTCHA_CREDETAIL:
            return {...state, recaptchKeys: action.data }
        case HIDE_RADIO_BUTTONS:
            return {...state, hideRadioButtons: action.data }
        case LOAD_CREATE_ACCOUNT:
            return { ...state, submitSuccess: action.data }
          default:
                return state;
    }
};