import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
import Modal from 'react-modal';

const JPLADataAgreement = (props) => {
    if (!props.modalOpen) return null;
    return (
        <Modal
            isOpen={props.modalOpen}
            shouldCloseOnOverlayClick={false}
            onRequestClose={props.handleModalOpen}
            className="Modal"
            contentLabel="Example Modal"
            overlayClassName="Overlay"
        >
            <div class="modal-header" style={{ display: "block" , paddingTop:"20px"}}>
                <h3 className='modalTitle'>Juniper Purchase License Agreement</h3>
                <button style={{ float: "right", cursor: "pointer", padding: 0, border: 0, marginTop: "-36px", marginRight: "10px" }} type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={props.handleModalOpen}><span style={{ display: "block", fontSize: "large", fontWeight: "bold", cursor: "pointer" }} onClick={props.handleModalOpen}>&times;</span></button>
            </div>
            <hr className="line-5" style={{margin: "0px"}}></hr>
            <div class="modal-body">
                <div className="padding-top">
                    <div className="">
                        <iframe title="JPLAAgreement"
                            width="100%"
                            height="100%"
                            src={props.data.jpla_s3_link}>
                        </iframe>
                    </div>

                    {props.activationFlag === false && <div className="" style={{ paddingBottom:"0px"}}>
                        <p className="the-agreement-you-ha" style={{ paddingLeft:"10px"}}>
                            I hereby certify and agree.
                        </p>
                    </div>}
                </div>
            </div>
            {props.activationFlag === false && <div class="modal-footer">
                <div className="modalButton nav-buttons">
                    <div>
                        <button onClick={props.handleUpdateJplaUserAttributr} className={"primary acceptButton rectangle-back-button"}>Accept</button>&nbsp; &nbsp;
                    </div>
                    <div className="padding-around-small">
                        <div className="cancel hyperlink-cus" onClick={props.handleModalOpen}> Cancel</div>
                    </div>
                </div>
            </div>}
        </Modal>
    );
}

export default JPLADataAgreement;