/****************************************************************
 * File : store.js
 * Description :-
 * 1. This files create store using combined reducer imported 
 *    from ./reducers index.js file.
 * 2. Actions are dispatched to Reducer through store.
 * 3. In order to handle asynchronous API calls successfully in the 
 *    application, middleware : thunk is used while creating store.
 * @author Gauri Yadav
 ****************************************************************/

import { createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'; // Middleware
import appReducer from './reducers'


const store = createStore(appReducer, applyMiddleware(thunk));



export default store