import React from "react"
import FooterHyperlinksComponent from "./FooterHyperlinksComponent";
import CustomerImage from '../style/images/icon-customers.png';
import SupplierImage from '../style/images/icon-supplier.png';

/*******************************************************
 * Screen - Login Assistance 
 * Description - This is the page used by customers for 
 *               login assistance.
 * @author : Gauri Yadav
 ******************************************************/
class LoginAssistanceComponent extends React.Component {
    componentDidMount = () => {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div>
                <div className="grid padding-around font-size-medium center-element width-80">
                    <div className="col-12">
                        <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p><br/>
                        <strong className="account-creation-n">Login Assistance</strong>. Log in assistance varies depending on User Account Type. Please see below for login assistance by user type.
                            <br /><br />
                        <p className="account-creation-n"><strong>User Account Type</strong></p><br />
                        <div className="grid flex-spread">
                            <div className="col-2">
                                {/* <i className="fas fa-user-friends fa-3x"></i><br /> */}
                                <img src={CustomerImage} alt="Customers"/>
                                <p className="account-type-label margin-top-small">Customers</p><br />
                                <p className="account-type-text"><span style={{fontWeight: "bold"}}>If you are a customer with an existing account</span> and require Technical or Non-Technical assistance, please create a case using <a href="https://supportportal.juniper.net/" alt="juniper support link" target="_blank" rel="noopener noreferrer">Juniper Support Portal</a> or <a href="https://support.juniper.net/support/requesting-support/" alt="contact customer support" target="_blank" rel="noopener noreferrer">contact Customer Support.</a>
                                <br/><br/>
                                <span style={{fontWeight: "bold"}}>If you require access</span> to the online <a href="https://supportportal.juniper.net/" alt="juniper support portal">Juniper Support Portal</a>, please create a user login account using <a href="/" alt="create user account">Create User Account.</a> Note: You will be required to provide either your Product Serial Number, Software Support Reference Number, Customer Authorization Code or Cloud Marketplace credentials for authentication.
                                </p>
                            </div>
                            
                            <div className="col-2">
                                <i className="far fa-handshake fa-3x"></i><br />
                                <p className="account-type-label margin-top-small">Juniper Channel Partners</p><br />
                                <p className="account-type-text">
                                Partner and Distribution Leaders should <span style={{fontWeight: "bold"}}>manage their company’s user access</span> through the Partner Assistance page in the Partner Relationship Manager Management Community <a href="https://juniper.my.site.com/prm/s/help" alt="juniper community" target="_blank" rel="noopener noreferrer">here.</a> (Authorization code is no longer required). 
                                </p>
                            </div>
                            
                            <div className="col-2">
                                <i className="fas fa-cloud fa-3x"></i><br />
                                <p className="account-type-label margin-top-small">Cloud Marketplace</p><br />
                                <p className="account-type-text"><span style={{fontWeight: "bold"}}>Cloud users with an existing approved account</span> wishing to discuss options for an upgrade or to evaluate our software products, please <a href="https://support.juniper.net/support/requesting-support/" alt="contact customer support" target="_blank" rel="noopener noreferrer">contact Customer Support via phone.</a>
                                <br/><br/>
                                <span style={{fontWeight: "bold"}}>If you have purchased a PAYG (Hourly or Yearly) Juniper subscription</span> directly from a Cloud Marketplace, please create a Cloud User Account using <a href="/" alt="create user account 2">Create User Account.</a> You will be required to provide your Cloud Marketplace credentials for authentication.  
                                </p>
                            </div>
                            
                            <div className="col-2">
                                {/* <i className="fas fa-parachute-box fa-3x"></i><br /> */}
                                <img src={SupplierImage} width="64" height="46" alt="supplier"></img>
                                <p className="account-type-label margin-top-small">Supplier</p><br />
                                <p className="account-type-text">
                                If you are a Juniper supplier <span style={{fontWeight: "bold"}}>wishing to gain access</span> to the Agile Portal to browse product information, please create a Supplier User account using <a href="/" alt="user registration home link">Create User Account.</a>


                                </p>
                            </div>
                            
                            <div className="col-2">
                                <i className="fas fa-id-badge fa-3x"></i><br />
                                <p className="account-type-label margin-top-small">Guests</p><br />
                                <p className="account-type-text">
                                    <span style={{fontWeight: "bold"}}>Guest users with an existing approved account</span> wishing to discuss options for an upgrade or to evaluate our software products, please <a href="https://support.juniper.net/support/requesting-support/" alt="cc support" target="_blank" rel="noopener noreferrer">contact Customer Support via phone.</a>
                                    <br/><br/>
                                    <span style={{fontWeight: "bold"}}>If you do not currently own a Juniper product and require access</span> to Elevate Community  or the Learning center, please create a Guest User account using Create User Account. <span style={{fontWeight: "bold"}}>If you require access</span> to EngNet, vLabs and other JCL tools, please create a EngNet, vLabs & other JCL Tools account using <a href="/" alt="create user accnt">Create User Account.</a>

                                </p>
                            </div>
                        </div>
                        <div className="additional-instructi">
                            <p style={{fontWeight: "bold", fontSize: "large"}}>
                                Customer Knowledge Base Resources
                            </p>
                            Additional instructions relating to user account registration can be found in <a href="https://kb.juniper.net/KB9946" target="_blank" rel="noopener noreferrer">KB9946 – Create New User Login with Juniper Networks</a>.<br /><br />

                            Additional details on getting started with Juniper can be found in <a href="https://kb.juniper.net/KB10910" target="_blank" rel="noopener noreferrer">KB10910 – Juniper Support – Getting Started Checklist </a>.<br /><br />

                            Additional details on Password Policy for Partner Users may be located in <a href="https://kb.juniper.net/KB33281" target="_blank" rel="noopener noreferrer">KB33281 - Partner User Password Reset Policy and FAQ</a>.<br /><br />

                            If you are facing issues with account creation or login and this page was unable to resolve your concern, please <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">Contact Customer Support</a> and we will be<br /> happy to assist you.<br /><br />
                        </div>
                        <FooterHyperlinksComponent history={this.props.history} isLoginAssistancePage={true}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginAssistanceComponent