import React from "react"

class EditAccountFieldsComponent extends React.Component{
    render(){
        return(
            <div className="margin-top prepopulated-container">
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Company Name</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.companyName !== null)? this.props.userInfo.companyName : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Partner Level</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.partnerLevel !== null)? this.props.userInfo.partnerLevel : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">VAR ID</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.varId !== null)? this.props.userInfo.varId : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Authcode</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.authCode !== null)? this.props.userInfo.authCode : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Partner Country ISO</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.partnerCountryISO !== null)? this.props.userInfo.partnerCountryISO : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Theater
                    </span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.theater !== null)? this.props.userInfo.theater : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Contact Role</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.contactRole !== null)? this.props.userInfo.contactRole : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields ">
                    <span className="label-container-pre">Champion Level</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.championLevel !== null)? this.props.userInfo.championLevel : 'N/A'}</span>
                </div>
                <div className="prepopulated-fields">
                    <span className="label-container-pre">Company URL</span>&nbsp;&nbsp;&nbsp;
                    <span className="label-span-pre">{(this.props.userInfo !== null && this.props.userInfo.companyUrl !== null)? this.props.userInfo.companyUrl : 'N/A'}</span>
                </div>
            </div>
        )
    }
}

export default EditAccountFieldsComponent;