export const initialState = {
    resetPassword : '',
    resetLoading : false
  }
  
  
  export const resetPwdReducer = (state = initialState,action) =>{
      switch (action.type) {
            case "RESET_PASSWORD" :
            return{
              ...state,
              resetPassword: action.resetPassword
            }
            case "RESET_LOADING" :
            return {
                ...state,
                resetLoading : action.resetLoading
            }
            case "RESET_DATA" : 
            return {
              ...state,
              resetPassword: ""
            }
          default:
                return state;
    }
  }