import React from "react";
import "../style/css/eloquaForm.css";

const INPUT_EL = [
    {name:"ENT", title: "Enterprise Networking" },
    {name:"SP", title: "Service Provider"},
    {name: "LOCALEVENTS", title: "Local Event Notifications"},
    {name: "GOV", title: "Government and Public Sector"},
    {name: "EDU", title: "Education"},
    {name: "TRAINING", title: "Juniper Training"},
    {name: "FINANCIAL", title: "Financial Services"},
    {name: "HEALTHCARE", title: "Healthcare"},
    {name: "RETAIL", title: "Retail"},
    {name: "TECHINSIDER", title: "Tech Insider Newsletter"},
    {name: "PARTNER", title: "Partner Communications (Partners Only)"}
];

const EloquaForm = (props) => {
    const {onChange, eloQua} = props

    const renderInputElement = (el, index, onChange, eloQua) => {

        return <div className="grid flex-horizontal" key={index+el.name}><label className="container"><input type="checkbox" name={el.name} checked={eloQua && eloQua.indexOf(el.name) > -1} onChange={onChange} /><span className="checkmark"></span></label> {el.title}</div>
    }

    return <div className="eloqua-form-main">
        <p><strong>Please select the email communications you wish to subscribe to from the list below.</strong></p>
        <div className="col-8">
            {INPUT_EL && INPUT_EL.map((el, index) => renderInputElement(el, index, onChange, eloQua) )}
        </div>
    </div>
}

export default EloquaForm;