import {postApiCall, getApiCall} from '../utils/index'

export const fetchJobFunction = url => {
    return dispatch => {
      postApiCall(url,{}).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_JOB_FUNCTION", jobFunction: res.jobFunctions });
           }).catch(err => {
            // Do something for an error here
           window.open('/errorpage','_self')
          });

    }
}


export const fetchTimeZones = (url) => {
    return dispatch => {
        postApiCall(url).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_TIME_ZONES", timeZones: res.timezones });
           }).catch(err => {
            // Do something for an error here
            window.open('/errorpage','_self')
          });
    }
}

export const fetchStates = (url, payload) => {
    return dispatch => {
        postApiCall(url, payload).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  let response = ""
                  if(res.length <= 1){
                      response = "No States Available"
                  }
                  else{
                       response = res.stateList
                  }
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_STATES", states: response });
             }).catch(err => {
                // Do something for an error here
                window.open('/errorpage','_self')
              });
      }
}

export const  getLoggedInUserInfo = (url) => {
    return dispatch => {
        dispatch({ type : "LOADING" , loadingStarts : true})
        getApiCall(url).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  dispatch(getUserByUserId(res.email))
             }).catch(err => {
                // Do something for an error here
              // window.open('/errorpage','_self')
              });
            }
}

/**
 * 
 * @param {String: email address} id 
 * @returns 
 */
 export const getUserByUserId = (id) => {
    return dispatch => {
        postApiCall("/getUserByUserId", {emailAddress: id}).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_ACCOUNT_INFO", loggedInUserInfo: res });
                  dispatch({ type : "LOADING" , loadingStarts : false})
             }).catch(err => {
                // Do something for an error here
               window.open('/errorpage','_self')
              });
        }
  }

export const updateAcctInformation = (url,payload) => {
    return dispatch => {
        dispatch({ type : "LOADING_UPDATE_ACCT" , savingLoader : true})
        postApiCall(url,payload).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  if(res.userId) {
                    dispatch({ type: "UPDATE_ACCT_INFO", acctInfoUpdated: 'Success' });
                  } else {
                    dispatch({ type: "UPDATE_ACCT_INFO", acctInfoUpdated: res.UpdateAccountInfoRes.ResMessage });
                  }
                  dispatch({ type : "LOADING_UPDATE_ACCT" , savingLoader : false})
             }).catch(err => {
                // Do something for an error here
              window.open('/errorpage','_self')
              });
  
      }
}

export const clearSuccessResponse = () => {
    return dispatch => {dispatch({type: "CLEAR_SUCCESS_RES"})}
}