/*********************************************************************
 * File : Utils/index.js
 * Description :- Commonly used functions and constants can be defined 
 *                here and can be used in entire application
 * 
 * @author : Gauri Yadav
 * 
 *********************************************************************/

require('es6-promise').polyfill();
require('isomorphic-fetch');
/***************************************
 * 
 * English Characters check
 **************************************/
  export const isEnglishChar = (event) =>{
      var val = event.target.value;
      // eslint-disable-next-line
      var letters = /^[\u0000-\u007F]*$/;
        if(val !== null && (val.match(letters) || val.length === 0)) {
           return true
      }else{
        return false
      }
}

/***************************************
 * 
 * Generic Fetch Post call function
 **************************************/
export const postApiCall = (url,payLoad) => {
  return fetch(url,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payLoad)
  })
}

/***************************************
 * 
 * Generic Fetch GET call function
 **************************************/
export const getApiCall = (url) => {
  return fetch(url)
}

