export const initialState = {
  jobFunction : [],
  timeZones : [],
  states : [],
  loggedInUserInfo : null,
  acctInfoUpdated : '',
  savingLoader : false,
  supplierInfo : {},
  accountMatchResponse : {},
  addLoader : false,
  jplaS3Agreement: {}
}

export const GET_JOB_FUNCTION = 'GET_JOB_FUNCTION';

export const accountcreationReducer = (state = initialState,action) =>{
    switch (action.type) {
        case GET_JOB_FUNCTION:
              return Object.assign({}, state, {
                jobFunction: action.jobFunction
              });
        case "GET_TIME_ZONES":
          return {
            ...state,
            timeZones: action.timeZones
          }
          case "GET_STATES":
            return{
              ...state,
              states: action.states
            }
          case "GET_ACCOUNT_INFO" :
          return{
            ...state,
            loggedInUserInfo: action.loggedInUserInfo
          }
          case "CLEAN_ACCT_INFO":
          return{
            ...state,
            loggedInUserInfo: null
          }
          case "UPDATE_ACCT_INFO":
          return{
            ...state,
            acctInfoUpdated: action.acctInfoUpdated
          }
          case "LOADING_UPDATE_ACCT":
                return {
                    ...state,
                    savingLoader : action.savingLoader
                }
          case "CLEAR_SUCCESS_RES":
            return{
              ...state,
              acctInfoUpdated : ''
            }
          case "GET_SUPPLIER_INFO":
            return{
              ...state,
              supplierInfo : action.supplierInfo
            }
            case "CLEAR_SUPLIER_INFO":
              return{
                ...state,
                supplierInfo : {}
              }
          case "GET_ACCOUNT_MATCH":
            return{
                  ...state,
                  accountMatchResponse : action.accountMatchResponse
            }
            case "ADD_LOADER":
            return{
                  ...state,
                  addLoader : action.addLoader
            }
            case "GET_JPLA_S3":
              return {
                ...state,
                jplaS3Agreement : action.jplaS3Agreement
              }
        default:
              return state;
  }
}