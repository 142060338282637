import React from "react"
import { fetchJobFunction, fetchTimeZones, fetchStates,getLoggedInUserInfo,updateAcctInformation,clearSuccessResponse} from '../actions/labUserAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { isEnglishChar } from '../utils'
import LoadingOverlay from 'react-loading-overlay';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";
import FooterHyperlinksComponent from "./FooterHyperlinksComponent"

class LabUserComponent extends React.Component {
    constructor(props){
    super(props)
    this.validateMandatoryFieldsAndError = this.validateMandatoryFieldsAndError.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.checkForEngChar = this.checkForEngChar.bind(this);
        this.renderJobFunction = this.renderJobFunction.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
        this.state = {
            emailAddress : "",
            firstName: "",
            lastName: "",
            companyName: "",
            jobTitle: "",
            jobFunc: -1,
            compUrl: "",
            phoneNum: "",
            phoneExt: "",
            fax: "",
            add1: "",
            add2: "",
            city: "",
            stateProvince: -1,
            stateName : "- Please Specify -",
            country : "",
            zip: "",
            timeZone: -1,
            ipAdd: "",
            authCode: "",
            serialNum:"",
            errorfirstName: false,
            errorlastName: false,
            errorcompanyName: false,
            errorjobFunc: false,
            errorphoneNum: false,
            erroradd1: false,
            errorcity: false,
            errorstateProvince: false,
            errorzip: false,
            errortimeZone: false,
            prepopulate: false,
            errorNonEngfirstName: false,
            errorNonEnglastName: false,
            errorNonEngcompanyName: false,
            errorNonEngjobTitle: false,
            errorNonEngcompUrl: false,
            errorNonEngphoneNum: false,
            errorNonEngphoneExt: false,
            errorNonEngfax: false,
            errorNonEngadd1: false,
            errorNonEngadd2: false,
            errorNonEngcity: false,
            errorNonEngzip: false,
            errorNonEngtimeZone: false,
            errorNonEngipAdd: false,
            errorNonEngauthCode: false,
            errorNonNumphoneNum : false,
            enableNextButton: false,
            isChecked: false,
            editMode : false,
            supplierMode : false,
            supplierAppsList : [],
            initialCompanyName : ""
        }
    }

    componentDidMount(){
        this.props.getLoggedInUserInfo('/getLoggedInUserInfo');
        const url = '/getJobFunctions';
        this.props.fetchJobFunction(url);
    }

    componentDidUpdate(prevProps){
        // console.log(prevProps.loggedInUserInfo,this.props.loggedInUserInfo)
        if(prevProps.loggedInUserInfo !== this.props.loggedInUserInfo){
            if(null !== this.props.loggedInUserInfo && this.props.loggedInUserInfo.userId !== null){
                const getTimeZoneURL = "/getTimezones";
                const getStateURL = "/getStates";
                let countryCode = "";
                if (this.props.loggedInUserInfo.country !== null) {
                    countryCode = this.props.loggedInUserInfo.country;
                }
                var countryBasedPayLoad = {
                    "countryCode": countryCode
                }
                this.props.fetchTimeZones(getTimeZoneURL, countryBasedPayLoad);
                this.props.fetchStates(getStateURL, countryBasedPayLoad);
                
                let userId, firstName, lastName, companyName, jobTitle, jobFunction, companyUrl, phoneNumber, faxNumber
                ,address1, address2, city, state, zip, timeZone,phoneExtension,PhoneCode;
                
                let loggedInUserInfo = this.props.loggedInUserInfo;
               
                    userId = loggedInUserInfo.userId === null ? 'N/A' : loggedInUserInfo.userId
                    firstName = loggedInUserInfo.firstName === null ? 'N/A' : loggedInUserInfo.firstName
                    lastName = loggedInUserInfo.lastName === null ? 'N/A' : loggedInUserInfo.lastName
                    companyName = loggedInUserInfo.companyName === null ? 'N/A' : loggedInUserInfo.companyName
                    jobTitle = loggedInUserInfo.jobTitle === null ? 'N/A' : loggedInUserInfo.jobTitle
                    jobFunction = loggedInUserInfo.jobFunction === null ? 'N/A' : loggedInUserInfo.jobFunction
                    companyUrl = loggedInUserInfo.companyUrl === null ? 'N/A' : loggedInUserInfo.companyUrl
                    if(loggedInUserInfo !== null){
                        if(loggedInUserInfo.phoneNumber === null){
                            phoneNumber = 'N/A'
                            phoneExtension = 'N/A'
                        }
                        else if(loggedInUserInfo.phoneNumber.toLowerCase().indexOf('ext#') > 0){
                            let phoneField = loggedInUserInfo.phoneNumber.toLowerCase().split("ext#").filter(data => data.trim() !== "");

                                if(phoneField.length > 1){
                                    phoneNumber = phoneField[0].trim()
                                    phoneExtension = phoneField[1].trim()
                                }
                                else{
                                    phoneNumber = phoneField[0].trim()
                                    phoneExtension = ""
                                }

                               let PhoneSplit = phoneNumber.split(" ").filter(data => data.trim() !== "")
                                if(PhoneSplit.length > 1){
                                    PhoneCode = PhoneSplit[0].trim()
                                    phoneNumber = PhoneSplit[1].trim()
                                }
                                else{
                                    PhoneCode = ''
                                    phoneNumber = PhoneSplit[0].trim()
                                }

                            }
                        else{
                            phoneNumber = loggedInUserInfo.phoneNumber;
                            phoneExtension = ''
                            PhoneCode = ""
                       }
                }
                    faxNumber = loggedInUserInfo.faxNumber === null ? 'N/A' : loggedInUserInfo.faxNumber
                    address1 = loggedInUserInfo.address1 === null ? 'N/A' : loggedInUserInfo.address1
                    address2 = loggedInUserInfo.address2 === null ? 'N/A' : loggedInUserInfo.address2
                    city = loggedInUserInfo.city === null ? 'N/A' : loggedInUserInfo.city
                    state = loggedInUserInfo.state === null ? 'N/A' : loggedInUserInfo.state
                    zip = loggedInUserInfo.zip === null ? 'N/A' : loggedInUserInfo.zip
                    timeZone = loggedInUserInfo.timeZone === null ? 'N/A' : loggedInUserInfo.timeZone
                    
                //Setting state

                this.setState({
                    emailAddress : userId,
                    firstName : firstName,
                    lastName : lastName,
                    companyName : companyName,
                    jobTitle : jobTitle,
                    jobFunc : jobFunction,
                    compUrl : companyUrl,
                    phoneCode : PhoneCode,
                    phoneNum : phoneNumber,
                    fax : faxNumber,
                    add1 : address1,
                    add2 : address2,
                    city : city,
                    stateProvince : state,
                    zip : zip,
                    timeZone : timeZone,
                    phoneExt : phoneExtension,
                    country : loggedInUserInfo.countryName ? loggedInUserInfo.countryName : "N/A",
                    initialCompanyName : companyName
                },() => {
                this.validateMandatoryFieldsAndError();
                        });
            }
        }
    }

        /*****************************************************************
     * Triggered : On change of any Input form field
     * Description : This function is used to update the field 
     *               value and instantly remove the error associated with
     *               that field.
     ******************************************************************/
    onChangeField(event) {
        this.setState({ [event.target.name]: event.target.value });
        var errorString = "error" + event.target.name;
        if (event.target.value.trim() === "" || event.target.value === "-1" || event.target.value === -1) {
            this.setState({ [errorString]: true }, () => {
                this.validateMandatoryFieldsAndError();
            });
        } else {
            this.setState({ [errorString]: false }, () => {
                this.validateMandatoryFieldsAndError();
            });
        }
        if(event.target.name === "stateProvince"){
            let index = event.nativeEvent.target.selectedIndex;
            let label = event.nativeEvent.target[index].text;
            this.setState({
                stateName : label
            })
        }
        this.checkForEngChar(event);
        this.checkforNumChar(event)
    }

    /*****************************************************************
     * Triggered : On blur of any Input form field
     * Description : This function is used to validate whether input provided
     *               is in english or not.
     ******************************************************************/
    checkForEngChar(event) {
        var isFieldContainsEnglishCharacter = isEnglishChar(event);
        var errorString = "errorNonEng" + event.target.name;
        if (!isFieldContainsEnglishCharacter) {
            this.setState({ [errorString]: true });
        } else {
            this.setState({ [errorString]: false });
        }
    }

    checkforNumChar(event){
        if(event.target.name === "phoneNum"){
        let val = event.target.value
        let errorString = "errorNonNum" + event.target.name;
        // eslint-disable-next-line
        var numbers = /^[0-9]*$/;
        if(val !== null && (val.match(numbers) || val.length === 0)){
            this.setState({[errorString] : false})
        }
        else{
            this.setState({[errorString] : true})
        }
    }
    }

    validateMandatoryFieldsAndError() {
        let { errorfirstName, errorlastName, errorcompanyName, errorjobFunc, errorphoneNum, erroradd1, errorcity, errorstateProvince, errorzip, errortimeZone, errorNonEngfirstName, errorNonEnglastName, errorNonEngcompanyName,
            errorNonEngjobTitle, errorNonEngcompUrl, errorNonEngphoneNum, errorNonEngphoneExt, errorNonEngfax, errorNonEngadd1, errorNonEngadd2, errorNonEngcity, errorNonEngzip, errorNonEngtimeZone, errorNonEngipAdd, errorNonEngauthCode,errorNonNumphoneNum,
            firstName, lastName, companyName, jobFunc, phoneNum, add1, city, stateProvince, zip, timeZone
        } = this.state
        let {states} = this.props;
        let allMandatoryFieldsFilled = true;
        if (errorfirstName || errorlastName || errorjobFunc || errorphoneNum || erroradd1 || errorcity || errorstateProvince || errorzip || errortimeZone || errorNonEngfirstName || errorNonEnglastName || errorNonEngjobTitle || errorNonEngcompUrl || errorNonEngphoneNum || errorNonEngphoneExt || errorNonEngfax || errorNonEngadd1 || errorNonEngadd2 || errorNonEngcity || errorNonEngzip || errorNonEngtimeZone || errorNonEngipAdd || errorNonEngauthCode || errorNonNumphoneNum || firstName === "" || 
        (states !== null && states !== "No States Available" && (stateProvince === "-1" || stateProvince === -1)) || lastName === "" || jobFunc === "-1" || jobFunc === -1 || phoneNum === "" || add1 === "" || city === "" || zip === "" || timeZone === "-1" || timeZone === -1 ||
            (companyName === "" && !this.state.isChecked)
            || errorNonEngcompanyName || errorcompanyName) {
            allMandatoryFieldsFilled = false;
        }
        //enable nxt button if all mandatory fields are full    
        if (allMandatoryFieldsFilled) {
            this.setState({
                enableNextButton: true
            })
        }
        else {
            this.setState({
                enableNextButton: false
            })
            return
        }
    }

    updateAcctInfo(countryCode){
        let updatedCompanyName = this.state.companyName
        if(this.state.isChecked){
            updatedCompanyName = "PERSONAL USE "+ this.state.firstName.toUpperCase() + " " + this.state.lastName.toUpperCase();
        }
        else{
            updatedCompanyName = this.state.companyName
        }

        let updateAcctReqPayload = {
            "firstName" : this.state.firstName,
            "lastName" : this.state.lastName,
            "companyName" : updatedCompanyName,
            "compURL" : this.state.compUrl,
            "jobTitle" : this.state.jobTitle,
            "jobFunction" : this.state.jobFunc,
            "phoneNumber" : this.state.phoneCode + ' ' + this.state.phoneNum + ' Ext# ' +this.state.phoneExt,
            "faxNumber" : this.state.fax,
            "addressLine1" : this.state.add1,
            "addressLine2" : this.state.add2,
            "city" : this.state.city,
            "state" : this.state.stateProvince,
            "zip" : this.state.zip,
            "countryCode" : countryCode,
            "timeZone" : this.state.timeZone,
            "isEvaluationUserReq" : false,
            "isLabUserReq" : true
    }
    this.props.loggedInUserInfo && this.props.updateAcctInformation(`${"/editUserAccount"}/${this.props.loggedInUserInfo.userId}`,updateAcctReqPayload);
    }

    onCheckboxClick(event) {
        this.setState({ isChecked: event.target.checked },
            () => {
                if (this.state.isChecked) {
                    this.setState({
                        companyName: "",
                        errorNonEngcompanyName: false,
                        errorcompanyName: false
                    }, () => {
                        this.validateMandatoryFieldsAndError();
                    })
                } else {
                    this.setState({
                        companyName: this.state.initialCompanyName,
                        errorNonEngcompanyName: false,
                        errorcompanyName: false
                    }, () => {
                        this.validateMandatoryFieldsAndError();
                    })
                }

            })
    }

    onKeyPressEvent(event){
        if((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode === 32){
            return true
        }
        else{
         event.preventDefault();
        return false
        }
     }

    /*****************************************************************
     * Function : 	 renderJobFunction()
     * Description : This function is used to generate country dropdown
	 * 				 options with list fetched from api.
     ******************************************************************/
    renderJobFunction(jobType) {
        return jobType.map((jobType, index) => {
            return <option key={"jobType" + index} value={jobType} >{jobType}</option>
        })
    }


    renderTimeZone(timeZones) {
        return timeZones.map((timeZone, index) => {
            return <option key={"timeZone" + index} value={timeZone} >{timeZone}</option>
        })
    }

    renderState(states) {
        if(states !== "No States Available"){
        return states.map((state, index) => {
            return <option key={"state" + index} value={state.stateCode} >{state.stateName}</option>
        })
    }
    }

    renderPhoneCode(){
            let {loggedInUserInfo} = this.props
                return <label type="text" className="col-3 label-input-text">{loggedInUserInfo && loggedInUserInfo.country} &nbsp;{this.state.phoneCode && this.state.phoneCode !== "" && !this.state.phoneCode.includes('+') ? '+' : ''}{this.state.phoneCode}</label>
    }

    closeDialog(){
        this.props.clearSuccessResponse()
    }

    render(){
        let {emailAddress} = this.state
        let {timeZones, states, loggedInUserInfo,savingLoader,loadingStarts} = this.props
        let isValidUser = loggedInUserInfo !== null && (loggedInUserInfo.userType === "Base User") ? true : false

        return(
            
            <div>
                {
                    loadingStarts &&
                    <div  style = {{ overflowY : 'auto', height : '480px'}} className="grid padding-around font-size-medium center-element width-80">
                    <div className="font-size-large margin-bottom">Loading...</div>
                    </div>
                }
                {!isValidUser && !loadingStarts &&
                <div  style = {{ overflowY : 'auto', height : '480px'}} className="grid padding-around font-size-medium center-element width-80">
                    <div className="col-12">
                    <div className="font-size-xlarge text-uppercase margin-bottom"><strong>ACCESS DENIED</strong>
                    </div>
                    <div className="message-container-error">
                                <p>You have encountered this error because your user account privileges do not permit access to the information or service requested.</p>
                                <p> To discuss options for user account upgrade please <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">Contact Customer Care</a>.</p>
                                <p>For further details please visit <span className='hyperlink' onClick={() => window.open('/loginassistance','_self')}>Login Assistance</span></p>
                    </div>
                </div>
                </div>
                }
                { isValidUser && !loadingStarts &&
                <>
                <LoadingOverlay
                    active={savingLoader}
                    spinner
                    text='Saving Account Information...'
                >
                <div  style = {{ overflowY : 'auto', height : '600px'}} className="grid padding-around font-size-medium center-element width-80">
                <div className="col-12">
                    <div className="flex margin-bottom-small header-container">
                            <div className="font-size-xlarge text-uppercase margin-bottom"><strong>ACCOUNT UPGRADE REQUIRED</strong></div>
                            { this.state.editMode && 
                            <div className="font-size-header">{loggedInUserInfo !== null && loggedInUserInfo.userId} |  
                            <span className="hyperlink padding-left-smaller">
                                <a href="/saml/logout">Logout</a>
                            </span>
                            </div>}
                    </div>
                    <br/>
                    <div className="message-container-warning">
                                <p>Juniper Networks User Registration System only accepts English characters. You will not be able to create an account using non-English characters (e.g. Asian languages and European characters).</p>
                                <p><span className="text-color-red">*Important Note*</span> Please ensure you provide accurate personal information to pass Juniper's compliance screening. The address and Zip/Postal Code need to be valid and in the correct format.</p>
                    </div>
                    <div className="margin-top-large margin-bottom-large  header-container">
                                <p>Hello {this.state.firstName} {this.state.lastName},</p>

                                <p>The portal or tool you are attempting to access requires a greater level of permission than your Juniper user account currently provides.</p>

                                <p>To submit your account for approval to be upgraded, please ensure you are entering accurate information in order to pass compliance screening.</p>
                    </div>
                    <div className="font-size-medium text-uppercase"><strong>Personal Information</strong></div>
                    {/* {isPartner &&
                            <EditAccountFieldsComponent userInfo={loggedInUserInfo}></EditAccountFieldsComponent>
                    } */}
                    <div className="padding-top" >
                        <div className="grid">
                                <div className="col-4">
                                    <p>
                                    <span className="label-container">Email Address</span>&nbsp;&nbsp;&nbsp;
                                    <span className="label-span">{loggedInUserInfo !== null ? loggedInUserInfo.userId : emailAddress}</span></p>
                                </div>
                        </div>
                        <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">First Name <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='firstName' 
                                        value={this.state.firstName}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="100" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)}/>
                                        {this.state.errorfirstName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide First Name!</p>}
                                        {this.state.errorNonEngfirstName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}

                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Last Name <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='lastName' 
                                        value={this.state.lastName}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="100" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)}/>
                                        {this.state.errorlastName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Last Name!</p>}
                                        {this.state.errorNonEnglastName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                        </div>
                        <div className="grid flex-horizontal col-4">
                                    <span className="label-container">Personal Use</span>&nbsp;&nbsp;&nbsp;
                                    <label className="container ">
                                        <input type="checkbox" value={this.state.isChecked} onClick={this.onCheckboxClick} ></input>
                                        <span className="checkmark"></span>
                                    </label>
                        </div>
                        <div className="grid">
                            <div className="col-6">
                                <p className="text-input-label">Company Name <span className="text-required">*</span> </p>
                                <div>
                                    <input type="text" placeholder="Type here..." name='companyName' 
                                    value={this.state.companyName}
                                     onChange={this.onChangeField} onBlur={this.checkForEngChar} required disabled={this.state.isChecked } maxLength="125" />
                                    {this.state.errorcompanyName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Company Name!</p>}
                                    {this.state.errorNonEngcompanyName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                </div>
                            </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Company URL </p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='compUrl' 
                                        value={this.state.compUrl} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="100" disabled={this.state.editMode}/>
                                        {this.state.errorNonEngcompUrl && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Job Title</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='jobTitle' 
                                        value={this.state.jobTitle}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="50" />
                                        {this.state.errorNonEngjobTitle && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Job Function <span className="text-required">*</span></p>
                                    <div>
                                        <select className="no-margin-top select-height" onChange={this.onChangeField} 
                                        value={this.state.jobFunc}
                                         name="jobFunc" required>
                                            <option value="-1">- Please Specify -</option>
                                            {this.renderJobFunction(this.props.jobFunction)}
                                        </select>
                                        {this.state.errorjobFunc && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Job Function!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Phone Number <span className="text-required">*</span></p>
                                    <div>
                                    <div className="flex">
                                        {this.renderPhoneCode()}
                                        <input type="text" placeholder="Type here..." name='phoneNum' 
                                        value={this.state.phoneNum}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" />
                                         </div>
                                        {this.state.errorphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Phone Number!</p>}
                                        {this.state.errorNonEngphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                        {this.state.errorNonNumphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Only Numeric characters allowed. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-3">
                                    <p className="text-input-label">Phone Extension</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='phoneExt' 
                                        value={this.state.phoneExt}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" />
                                        {this.state.errorNonEngphoneExt && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Fax Number</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='fax' 
                                        value={this.state.fax} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" />
                                        {this.state.errorNonEngfax && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Address 1 <span className="text-required">*</span></p>
                                    <div>
                                        {this.state.supplierMode}
                                        <input type="text" placeholder="Type here..." name='add1' 
                                        value={this.state.add1} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="40" disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.erroradd1 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Address 1!</p>}
                                        {this.state.errorNonEngadd1 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Address 2</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='add2' 
                                        value={this.state.add2}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="40" disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorNonEngadd2 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">City <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='city' 
                                        value={this.state.city} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="40" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)} disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorcity && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide City!</p>}
                                        {this.state.errorNonEngcity && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-5">
                                    <p className="text-input-label">State/Province
                                    {states !== "No States Available" &&
                                    <span className ="text-required">*</span>
                                    }
                                    </p>
                                    <div>
                                        <select className="no-margin-top select-height" onChange={this.onChangeField} 
                                        value={this.state.stateProvince} 
                                        name="stateProvince" required = {states !== "No States Available" ? true : false}
                                        disabled={!this.editMode && this.state.supplierMode}>
                                            <option value="-1">{states === "No States Available" ? "- No States Available -"  : "- Please Specify -"}</option>
                                            {this.renderState(states)}
                                        </select>
                                        {this.state.errorstateProvince && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide State/Province!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-4">
                                    <p className="text-input-label">Zip/Postal Code <span className="text-required">*</span>
                                    <span className="padding-top-smaller padding-left-smaller text-hint">(Type N/A if not applicable)</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='zip' 
                                        value={this.state.zip} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="10" 
                                        disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorzip && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Zip/Postal Code!</p>}
                                        {this.state.errorNonEngzip && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="email-address-span"> <span className="email-address">Country</span>&nbsp;&nbsp;&nbsp; <span className="spiderman-webcrawler">
                                    {this.state.country}</span></p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Time Zone <span className="text-required">*</span></p>
                                    <div>
                                        <select className="no-margin-top" onChange={this.onChangeField} 
                                        value={this.state.timeZone} 
                                        name="timeZone" required>
                                            <option value="-1">- Please Specify -</option>
                                            {this.renderTimeZone(timeZones)}
                                        </select>
                                        {this.state.errortimeZone && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Time Zone!</p>}
                                        {this.state.errorNonEngtimeZone && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid nav-buttons">
                                 <button onClick={() => this.updateAcctInfo(loggedInUserInfo.country)} className={this.state.enableNextButton ? "primary" : "primary disabled"}><span className="back">Save</span></button>
                            </div>
                    </div>
                    <FooterHyperlinksComponent history={this.props.history} />
                </div>
                </div>
                </LoadingOverlay>
                <DisclaimerDomainDialog dialogName = "confirmation-dialog" accountTitle={"Account Upgrade Request"} accountText={<div> <strong>CONFIRMATION</strong>
                <div className="margin-top-small text-align-justify">
                    <p>Your request to upgrade your Juniper user account has been submitted successfully.</p>

<p>You will receive an update via email, within 24 hours once your account has been screened by our compliance teams.</p>

<p>If you require further assistance, or you do not receive an e-mail within 24 hours, please contact <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">Customer Care</a>.</p>
                </div>
                </div>}
                openDialog = {this.props.acctInfoUpdated === 'Success' ? true : false} history = {this.props.history}closeDialog = {this.closeDialog.bind(this)}/>
                </>
                }
            </div>
                
        )
    }
}

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state) {
    return {
        jobFunction: state.labUserReducer.jobFunction,
        timeZones: state.labUserReducer.timeZones,
        states: state.labUserReducer.states,
        loggedInUserInfo : state.labUserReducer.loggedInUserInfo,
        acctInfoUpdated : state.labUserReducer.acctInfoUpdated,
        savingLoader : state.labUserReducer.savingLoader,
        loadingStarts : state.labUserReducer.loadingStarts
    }
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchJobFunction,
    fetchTimeZones,
    fetchStates,
    getLoggedInUserInfo,
    updateAcctInformation,
    clearSuccessResponse
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(LabUserComponent)
