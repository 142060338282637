import React from "react"

/********************************************************************************************
 * Screen - PublicEmailDomainDialog
 * Description - This is the component that renders Dialog box displaying Public Email Domain
 * 				 disclaimer.
 *              
 * @author : Gauri Yadav
 ******************************************************************************************/
export default class PublicEmailDomainDialogComponent extends React.Component {
	constructor(props) {
		super();
		this.closeDialog = this.closeDialog.bind(this);
		this.proceedDialog = this.proceedDialog.bind(this);
	}

	/**********************************************************
     * Triggered : On click of Ok Got It! button
     * Description : This function is used to close the dialog
	 * 				 box.
     *********************************************************/
	closeDialog() {
		this.props.closeDialog();
	}

	proceedDialog(){
		this.props.proceedDialog()
	}

	closeDialogBox() {
		this.props.closeDialog();
		if(this.props.accountTitle === "SET PASSWORD CONFIRMATION" || this.props.accountTitle === "ACCEPT ENCRYPTION AGREEMENT"){
			window.open("about:blank", "_self");
			window.close();
		}
		else if(this.props.accountTitle === "RESET PASSWORD CONFIRMATION"){
			window.open("https://www.juniper.net/", "_self");
		}
		else if(this.props.accountTitle === "Account Upgrade Request"){
			window.open("https://vlabs.juniper.net", "_self");
		}
		else{
			this.props.history.push('/');
		}
	}
	render() {
		return (
			<div>
				<div className={this.props.openDialog ? 'dialog-open' : 'dialog-close'}>
					<div className={`${this.props.dialogName} center-align`}>
						<div className="overlay-container">
							<div className="overlay-backdrop"></div>
							{/** Public Domain Dialog Box  */}
							{this.props.dialogName === 'public-email-domain' &&
								<div className="dialog">
									<p className="font-size-large text-uppercase"><b>Public Email Domain Recognized</b></p>
									<p className="text-left">Please note that Juniper Networks accepts public email domains (Hotmail, Gmail, etc.) for Guest User account creation only. Guest User account will provide access to the Elevate Community Forums and to the Juniper Learning Portal for all training. It will also enable product information browsing and access to other collaboration areas.
									<br /><br />
									Should you require access to tools such as EngNet, vLabs (https://jlabs.juniper.net/home/) or JCL, please use your company email address, as use of public domain email address for this option will be auto-declined. If you still need to use a public domain email address, please contact Juniper Customer Care for compliance screening assistance. 
									<br /><br />
									If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code.
									</p>
									<span className="hyperlink margin-0" onClick={this.closeDialog.bind(this)}><button onClick={this.props.enableNextButton} className="primary">OK! Got it!</button></span>
								</div>
							}
							{/** Juniper Domain Dialog Box  */}
							{this.props.dialogName === 'juniper-email-domain' &&
								<div className="dialog">
									<p className="font-size-large text-uppercase"><b>ACCOUNT INFORMATION SUBMITTED</b></p>
									<p className="font-size-medium"><b>Account Not Created</b></p>
									<p className="text-left">If you are a Juniper Networks employee, please note that employees do not need to sign up for Customer Support Center (CSC) or Partner Center accounts. Employees may access the CSC and the Partner Center using their internal User ID (sometimes called "User Name") and password. If you continue to have difficulties accessing a website, please contact the helpdesk.</p>
									<br></br>
									<p className="text-left">If you are not a Juniper employee and continue to have difficulties Creating an Account, please contact Customer Support using the Issue Details form at the bottom of the Login Assistance page.</p>
									<span className ="hyperlink">
									<button className="primary" onClick={this.closeDialog.bind(this)}>OK! Got it!</button>
									</span>
								</div>
							}
							{/** Dialog Boxes for confirmation on password screens and edit acc info */}
							{this.props.dialogName === 'confirmation-dialog' &&
								<div className="dialog-confirmation">
									<div className="font-size-large text-uppercase"><b>{this.props.accountTitle}</b></div>
									<div className="padding-around">{this.props.accountText}</div>
									<span className="hyperlink" onClick={this.closeDialogBox.bind(this)}>
										<button className="primary">OK! Got it!</button>
									</span>
								</div>
							}
							{/** Dialog Boxes for confirmation on Next Button for account creation screen */}
							{this.props.dialogName === 'compliance-dialog' &&
								<div className="dialog-confirmation">
									<div className="padding-around">{this.props.accountText}</div>
									<span className="hyperlink margin-right" onClick={this.closeDialog.bind(this)}>
										<button className="primary">Back</button>
									</span>
									<span className="hyperlink" onClick={this.proceedDialog.bind(this)}>
										<button className="primary">Proceed</button>
									</span>
								</div>
							}
							{/** Dialog Boxes for selection on Generic radio Button for account setup screen */}
                            {this.props.dialogName === 'group-email-dialog' &&
                                <div className="dialog">
                                    <p className="font-size-large text-uppercase"><b>Group Email Recognized</b></p>
                                    <p className="text-left">Please note that Juniper Networks accepts Group Emails for registration. Compliance-approved Group Email accounts will gain Customer Support Center access to our full suite of self-service tools and utilities, except for Software Downloads.
                                    <br /><br />
                                    Should you choose to proceed further with your group email registration, your account will be subject to the standard screening process but no software access can be provided.</p>
                                    <span className="hyperlink margin-0" onClick={() =>this.props.closeEmailDialog()}>
                                        <button className="primary">OK! Got it!</button>
                                    </span>
                                </div>
                            }
							{this.props.dialogName === 'address-dialog' && this.props.address &&
								<div className="dialog">
									<p className="font-size-large text-uppercase"><b>Address Match Found</b></p>
									<div className="padding-around">Do you wish to continue with below address?</div>
									<div><strong>
									<div className="font-size-medium">{this.props.address.NAME1}</div><br/>
									<div>{this.props.address.ADDRESS_L1}, {this.props.address.ADDRESS_L2}</div><br/>
									<div>{this.props.address.CITY}, {this.props.address.REGION},</div><br/>
									<div>{this.props.address.COUNTRY}, {this.props.address.POST_CODE1}</div>
									</strong></div>
									<br></br>
									<span className="hyperlink margin-0">
                                        <button className="primary margin-right" onClick={() =>this.props.closeAddressDialog("Y")}>YES</button>
										<button className="primary" onClick={() =>this.props.closeAddressDialog()}>NO</button>
                                    </span>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

}