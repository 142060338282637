export const ELQ_EDIT_URL= (env) => {
    if(env) return "https://uat-aem.juniper.net/us/en/forms/subscription-management.html"

    return "https://www.juniper.net/us/en/forms/subscription-management.html"
}

export const SITE_KEY_RECAPTCHA= (env) => {
    if(env) return "6Ledfu0gAAAAAGKWT3Lv3QiuJBNtgzkZ0P8h0HBb"

    return "6Ldt82YgAAAAAON07G3efMEIsEhTgESvr3jIWL3X"
}

export const secretKey = "secretkeyforuseregcryptojs"

export const EMBARGO_COUNTRY_LIST = ["Iran, Islamic Republic Of", "Cuba", "North Korea", "Syrian Arab Republic", "Russia Federation", "Belarus"]

export const SITE_KEY = "6Ldt82YgAAAAAON07G3efMEIsEhTgESvr3jIWL3X"

export const CLOUD_USER = "Cloud User"
