/***************************************************************************
 * File : combineReducer
 * Description :-
 * 1. This file is used to combine multiple reducers at one place and expose 
 *    just one reducer that will be consumed by store.
 * 2. Multiple reducers are set as a json object e.g. key : value
 * @author Gauri Yadav
 **************************************************************************/

import { combineReducers } from 'redux'
import {accountsetupReducer} from './accountsetupReducer'
import {accountcreationReducer} from './accountcreationReducer'
import {encryptionAgreementReducer} from './encryptionAgreementReducer'
import {cloudReducer} from './cloudReducer'
import {changePwdReducer} from './changePasswordReducer'
import {resetPwdReducer} from './resetPasswordReducer'
import {labUserReducer} from "./labUserReducer"

const config = {
    accountsetupReducer : accountsetupReducer,
    accountcreationReducer : accountcreationReducer,
    encryptionAgreementReducer :encryptionAgreementReducer,
    cloudReducer : cloudReducer,
    changePwdReducer : changePwdReducer,
    resetPwdReducer : resetPwdReducer,
    labUserReducer : labUserReducer
    
    //Add custom reducers here as key : value pair
}

const appReducer = combineReducers(config);

export default appReducer