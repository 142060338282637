import {postApiCall, getApiCall} from '../utils/index'

export const GET_JOB_FUNCTION = 'GET_JOB_FUNCTION';
const getUserByUserIdUrl  = "/getUserByUserId";

export const fetchJobFunction = url => {
    return dispatch => {
        postApiCall(url,{}).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: GET_JOB_FUNCTION, jobFunction: res.jobFunctions });
           }).catch(err => {
            // Do something for an error here
            window.open('/errorpage','_self')
          });

    }
}

export const fetchTimeZones = (url) => {
    return dispatch => {
        postApiCall(url).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_TIME_ZONES", timeZones: res.timezones });
           }).catch(err => {
            // Do something for an error here
            window.open('/errorpage','_self')
          });

    }
}

export const fetchStates = (url, payload) => {
    return dispatch => {
        postApiCall(url, payload).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  let response = ""
                  if(res.length <= 1){
                      response = "No States Available"
                  }
                  else{
                       response = res.stateList
                  }
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_STATES", states: response });
             }).catch(err => {
                // Do something for an error here
                window.open('/errorpage','_self')
              });
  
      }
}

export const  getLoggedInUserInfo = (url) => {
    return dispatch => {
        getApiCall(url).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch will call reducer when data is available.
                  dispatch(getUserByUserId(res.email))
			 }).catch(err => {
                // Do something for an error here
               window.open('/errorpage','_self')
              });
            }
}

/**
 * 
 * @param {String: email address} id 
 * @returns 
 */
export const getUserByUserId = (id) => {
    return dispatch => {
        postApiCall(getUserByUserIdUrl, {emailAddress: id}).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_ACCOUNT_INFO", loggedInUserInfo: res });
             }).catch(err => {
                // Do something for an error here
               window.open('/errorpage','_self')
              });
        }
}

export const CleanAcctInfo = () => {
    return dispatch => {dispatch({type: "CLEAN_ACCT_INFO"})}
}

export const updateAcctInformation = (url,payload) => {
    return dispatch => {
        dispatch({ type : "LOADING_UPDATE_ACCT" , savingLoader : true})
        postApiCall(url,payload).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch will call reducer when data is available.
                  if(res.userId) {
                    dispatch({ type: "UPDATE_ACCT_INFO", acctInfoUpdated: 'Success' });
                  } else {
                    dispatch({ type: "UPDATE_ACCT_INFO", acctInfoUpdated: res.UpdateAccountInfoRes.ResMessage });
                  }
                  
                  dispatch({ type : "LOADING_UPDATE_ACCT" , savingLoader : false})
             }).catch(err => {
                // Do something for an error here
              window.open('/errorpage','_self')
              });
  
      }
}

export const clearSuccessResponse = () => {
    return dispatch => {dispatch({type: "CLEAR_SUCCESS_RES"})}
}

export const getSupplierInfo = (url,payload) => {
    return dispatch => {
        postApiCall(url,payload).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  let supplierObj = {
                    SupplierApps: res.supplierApps,
                    SupplierProfile: res.supplierProfile

                  }
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_SUPPLIER_INFO", supplierInfo: supplierObj });
             }).catch(err => {
                // Do something for an error here
              window.open('/errorpage','_self')
              });
  
      }
}

export const clearSupplierInfo = () => {
    return dispatch => {dispatch({type: "CLEAR_SUPLIER_INFO"})}
}

export const getAccountMatch = (url,payload) => {
    return dispatch => {
        dispatch({ type : "ADD_LOADER" , addLoader : true})
        postApiCall(url,payload).then(res => {
            return res.json(); //If call is success then move to next promise
        })
        .then(response => {
            //dispatch willl call reducer when data is available.
            if(response.status && response.status === "INTERNAL_SERVER_ERROR") {
                let resObj = {"BP_MATCHED_FLAG":"0","T_ERROR_LIST":[],"T_MATCHED_BPS":[],"ERROR_MSG":"","ADDRESS_DATA_OUT":[{}],"BP_CREATE_FLAG":""}
                dispatch({ type: "GET_ACCOUNT_MATCH", accountMatchResponse:  resObj });
            } else {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_ACCOUNT_MATCH", accountMatchResponse:  response});
            }

       })
       .catch(err => {
          // Do something for an error here
          let resObj = {"BP_MATCHED_FLAG":"0","T_ERROR_LIST":[],"T_MATCHED_BPS":[],"ERROR_MSG":"","ADDRESS_DATA_OUT":[{}],"BP_CREATE_FLAG":""}
          dispatch({ type: "GET_ACCOUNT_MATCH", accountMatchResponse:  resObj });

        })
        .finally(() => {
            dispatch({type: "ADD_LOADER",addLoader : false})
        })
      }
}

export const getJPLAS3Agreement = () => {
    return dispatch => {
        postApiCall("/getJPLALatestVersion").then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  dispatch({ type: "GET_JPLA_S3", jplaS3Agreement: res });
             }).catch(err => {
                // Do something for an error here
               window.open('/errorpage','_self')
              });
        }
  }
