import React from "react"

/********************************************************************************************
 * Screen - Footer Hyperlinks
 * Description - This is the component that renders bottom section having following links :-
 *               a)Login Assistence
 *               a)Create User Account
 *               a)Edit Account Information
 *               a)Change Password 
 *               a)Reset Password
 * @author : Gauri Yadav
 ******************************************************************************************/

class FooterHyperlinksComponent extends React.Component {
    constructor(props){
        super();
        this.navigateToPage = this.navigateToPage.bind(this);
    }
    /**********************************************************
     * Triggered : On click of Login Assistance hyperlink
     * Description : This function is used to navigate to 
     *               Login Assistance page.
     * 
     *********************************************************/
    navigateToPage(url){
        this.props.history.push({
           pathname : url
        });
    }

    render() {
        const {isLoginAssistancePage} = this.props;

        return (
            <div className="padding-top-large grid">
                <div className="row-to-column">
                    
                    {!isLoginAssistancePage && <div style={{display: "flex"}}><div className="hyperlink" onClick={() => {  this.navigateToPage('/loginassistance') }} >Login Assistance</div> &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="hyperlink" onClick={() => { this.navigateToPage("/") }} >Create User Account</div> &nbsp;&nbsp;&nbsp;&nbsp;
                    
                    </div>
                    }

                    <div className="hyperlink" onClick={() => { window.open('/editaccountinformation','_self') }} >Edit Customer User Account</div> &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="hyperlink" >
                        <a className="hyperlink" href="https://juniper.my.site.com/prm/s" target="_blank" rel="noopener noreferrer">Edit Partner User Account</a>
                    </div> &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="hyperlink" onClick={() => { window.open('/changepassword','_self') }} >Change Password</div> &nbsp;&nbsp;&nbsp;&nbsp; 
                    <div className="hyperlink" onClick={() => {  this.navigateToPage("/resetpassword") }} >Reset Password</div>
                </div>
            </div>
        )
    }
}


export default FooterHyperlinksComponent