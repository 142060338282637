export const initialState = {
    cloudProviderList : [],
    cloudDetail : {},
    validateCloudDataAcctId : {},
    validateCloudDataLoader : false
  }
  
  export const FETCH_CLOUD_PROVIDER = 'FETCH_CLOUD_PROVIDER';
  export const FETCH_CLOUD_REGIONS_AND_PRODUCTS = 'FETCH_CLOUD_REGIONS_AND_PRODUCTS';
  
  export const cloudReducer = (state = initialState,action) =>{
      switch (action.type) {
          case FETCH_CLOUD_PROVIDER:
          return {
            ...state,
            cloudProviderList: action.cloudProviderList
          }
          case FETCH_CLOUD_REGIONS_AND_PRODUCTS:
          return {
            ...state,
            cloudDetail : action.cloudDetail
          }
          case 'VALIDATE_CLOUD_DATA' :
          return {
            ...state,
            validateCloudDataAcctId : action.validateCloudDataAcctId
          }
          case 'CLEAR_VALIDATE_DATA' :
          return {
            ...state,
            validateCloudDataAcctId : ''
          }
          case 'VALIDATE_DATA_LOADER' :
          return {
            ...state,
            validateCloudDataLoader : action.validateCloudDataLoader
          }
          default:
                return state;
    }
  }