import React from "react"
import FooterHyperlinks from './FooterHyperlinksComponent'
import ReactTooltip from 'react-tooltip'
import BarLoader from 'react-spinners/BarLoader';
import LoadingOverlay from 'react-loading-overlay';
//Utils
import { isEnglishChar } from '../utils'
//Redux
import { getLoggedInUserInfo, validatePwd, removeError, resetChangePasswordValues, 
    checkPwdComplexity, updatePassword, getUnlockedUserInfo,setPassword } from '../actions/changePasswordAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";


/*********************************************************
 * Screen - Change Password 
 * Description - This page is used for creating change 
 *               password request.
 * @author : Gauri Yadav
 *********************************************************/
class ChangePasswordComponent extends React.Component {
    constructor(props) {
        super();
        this.validateReenterField = this.validateReenterField.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.validateChangePwdForm = this.validateChangePwdForm.bind(this);
        this.checkForEngChar = this.checkForEngChar.bind(this);
        this.blockSpecialChar = this.blockSpecialChar.bind(this);
        this.updateNewPassword = this.updateNewPassword.bind(this);
        this.setNewPassword = this.setNewPassword.bind(this);
        this.state = {
            erroroldPwd: false,
            errornewPwd: false,
            errorreEnterNewPwd: false,
            pwdMtched: true,
            reEnterNewPwd: "",
            oldPwd: "",
            newPwd: "",
            errorNonEngoldPwd: false,
            errorNonEngnewPwd: false,
            errorNonEngreEnterNewPwd: false,
            enableSubmit: false,
            resetPasswordMode : false,
            invalidSpecialChar : false
        }
    }
    componentDidMount = () => {
        window.scrollTo(0, 0);
        if (this.props.location.pathname.indexOf('changePasswordWithUnlockKey') < 0) {
            this.setState({resetPasswordMode : false});
            this.props.getLoggedInUserInfo('/getLoggedInUserInfo');
        }else{
            this.setState({resetPasswordMode : true});
            this.props.getUnlockedUserInfo('/getUnlockUserInfo');
        }
    }
    /*****************************************************************
     * Triggered : On change of any Input form field
     * Description : This function is used to update the field 
     *               value and instantly remove the error associated with
     *               that field.
     ******************************************************************/
    onChangeField(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'oldPwd') {
            this.props.removeError(event.target.name);
        }
        if (event.target.name === 'newPwd') {
            this.props.removeError(event.target.name);
        }
        var errorString = "error" + event.target.name;
        if (event.target.value.trim() === "") {
            this.setState({ [errorString]: true }, () => {
                this.validateChangePwdForm();
            });
        } else {
            this.setState({ [errorString]: false }, () => {
                this.validateChangePwdForm();
            });
        }
        if (event.target.name === "newPwd" || event.target.name === "reEnterNewPwd") {
            if (this.state.oldPwd.trim() === "") {
                this.setState({ erroroldPwd: true }, () => {
                    this.validateChangePwdForm();
                });
            }
        }
        this.validateReenterField(event);
    }

    /********************************************************************
     * Triggered : On blur of Enter new password and reenter new password
     * Description : This function is used to validate whether New Password
     *               and Renter New password matches or not.
     *******************************************************************/
    validateReenterField(event) {
        var str1 = "";
        var str2 = "";
        if (event.target.name === "reEnterNewPwd") {
            str1 = this.state.newPwd;
            str2 = event.target.value;
        }
        if (event.target.name === "newPwd") {
            str1 = this.state.reEnterNewPwd;
            str2 = event.target.value;
            this.blockSpecialChar(event);
        }
        if(event.target.name === 'oldPwd'){
            str1 = this.state.newPwd;
            str2 = this.state.reEnterNewPwd;
        }
        if (str1.trim() !== "" && str2.trim() !== "" && str1.localeCompare(str2) !== 0) {
            this.setState({ pwdMtched: false }, () => {
                this.validateChangePwdForm();
            });
        } else {
            this.setState({ pwdMtched: true }, () => {
                this.validateChangePwdForm();
            });
        }

        this.checkForEngChar(event);

    }

    /********************************************************************
     * Triggered : On click of submit
     * Description : This function is used to validate all mandatory 
     *******************************************************************/
    validateChangePwdForm() {
        var allRequiredConditionsFulfilled = true;
        if (this.state.errorNonEngoldPwd || this.state.errorNonEngnewPwd || this.state.errorNonEngreEnterNewPwd
            || this.state.pwdMtched === false || this.state.erroroldPwd || this.state.errornewPwd || this.state.errorreEnterNewPwd
            || this.state.oldPwd.trim() === "" || this.state.newPwd.trim() === "" || this.state.reEnterNewPwd.trim() === "" || this.state.invalidSpecialChar) {
            allRequiredConditionsFulfilled = false;
        }
        if (allRequiredConditionsFulfilled) {
            this.setState({ enableSubmit: true })
        } else {
            this.setState({ enableSubmit: false })
        }
    }

    /*****************************************************************
     * Triggered : On blur of any Input form field
     * Description : This function is used to validate whether input provided
     *               is in english or not.
     ******************************************************************/
    checkForEngChar(event) {
        var isFieldContainsEnglishCharacter = isEnglishChar(event);
        var errorString = "errorNonEng" + event.target.name;
        if (!isFieldContainsEnglishCharacter) {
            this.setState({ [errorString]: true }, () => {
                this.validateChangePwdForm();
            });
        } else {
            this.setState({ [errorString]: false }, () => {
                this.validateChangePwdForm();
            });
        }
    }

    blockSpecialChar(event) {
        let val = event.target.value;
        // eslint-disable-next-line
        let letters = /^[~!@#$%^&*()_+{}.A-Za-z0-9]+$/
        if(val.match(letters)) {
            this.setState({ invalidSpecialChar: false }, () => {
                this.validateChangePwdForm();
            });
        }else{
            this.setState({ invalidSpecialChar: true }, () => {
                this.validateChangePwdForm();
            });
        }
    }

    /***************************************************************************
     * Triggered : OnPaste of Reenter- Password
     * Description : This function is used pasting inside Re-enter new Password
     **************************************************************************/
    preventPasting(event) {
        event.preventDefault();
    }

    /***************************************************************************
     * Triggered : OnBlur of New Password
     * Description : This function is validate password complexity and display 
     *               respective error if not valid.
     **************************************************************************/
    validateNewPassword() {
        let encodedString = btoa(this.state.newPwd); //Encrypting password to Base 64
        let checkPwdComplexity = {
            "password": encodedString
        }
        if(this.state.newPwd !== "" && !this.state.invalidSpecialChar && !this.state.errorNonEngnewPwd){
        this.props.checkPwdComplexity('/checkPasswordComplexity', checkPwdComplexity)
        }
    }
    // validateOldPwd(){
    //     let encodedString = btoa(this.state.oldPwd); //Encrypting password to Base 64
    //         let validatePwdPayload = {
    //             "emailAddress": this.props.loggedInUserInfo.UserId,
    //             "password": encodedString
    //         }
    //     if(this.state.oldPwd !== ""){
    //     this.props.validatePwd('/validatePassword', validatePwdPayload);
    //     }
    // }

    /***************************************************************************
     * Triggered : onclick of Submit button
     * Description : This function is used to invoke update Password API
     **************************************************************************/
    updateNewPassword() {
        var encodedString = btoa(this.state.newPwd); //Encrypting password to Base 64
        let oldPwd = btoa(this.state.oldPwd);
        var updatePwdPayload = {
            "oldPassword" : oldPwd,
            "password" : encodedString
        }
        if(!this.state.resetPasswordMode){
        this.props.updatePassword('/updatePasswordOkta', updatePwdPayload);
        }
    }
      /***************************************************************************
     * Triggered : onclick of Submit button of changePasswordwith unlock key page
     * Description : This function is used to invoke update Password API
     **************************************************************************/
    setNewPassword(){
        var encodedString = btoa(this.state.newPwd);
        let resetPwdPayload = {
            "password" : encodedString
        }
            this.props.setPassword('/resetPasswordOkta', resetPwdPayload);
    }
    closeDialog() {
        this.props.resetChangePasswordValues()
    }
    renderPasswordRes(){
        let updateStr = this.props.checkPwdComplexityRes.trim();
        let str = updateStr.replace("Password should be more than 12 characters", "Password should be 12 or more characters")
        let res = str.split('.');
        return res.splice(0,res.length-1).map((text,index) => {
            if(index === 0){
            return(
            <span key={index}>{text}.<br/></span>)
            }
            else{
            return(
            <span className="padding-left" key={index}>{text}.<br/></span>
            )}
        })
        }
    render() {
        let { loggedInUserInfo, updatePwdRes,setPwdRes,newPwdValidateLoader, 
            unlockedUserInfo, unlockInfoLoader} = this.props;
            let firstName  = unlockedUserInfo.firstName !== undefined ? unlockedUserInfo.firstName : ""
            let lastName  = unlockedUserInfo.lastName !== undefined ? unlockedUserInfo.lastName : ""
            let fullName = firstName + " " + lastName
        const accountText = (<div className="padding-around text-align-justify">
            <p className="padding-top">Your new password will be effective within 5 minutes.
                To ensure successful login, please close all of your browser windows and restart your browser.
                When you return, you will then be able to access your account using your new User ID and Password.</p>
            <h3>Next Steps</h3>
            <p>Use the links indicated below to login to your secured area:</p>
            <ul>
                <li className="margin-left-point-five-em"><strong>Customers:</strong> Log in to the <a target='_blank' rel="noopener noreferrer" href="https://www.juniper.net/customers/support/">Customer Support Center</a></li>
                <li className="margin-left-point-five-em"><strong>Partners:</strong> Log in to the <a target='_blank' rel="noopener noreferrer" href="https://www.juniper.net/partners/partner_center/">Partner Center</a></li>
                <li className="margin-left-point-five-em"><strong>Elevate Community Members:</strong> Log in to the <a target='_blank'  rel="noopener noreferrer" href="https://community.juniper.net/home">Elevate Community</a></li>
                <li className="margin-left-point-five-em"><strong>Start Training:</strong> Log in to the <a target='_blank'  rel="noopener noreferrer" href="https://learningportal.juniper.net/">Learning Portal</a></li>
            </ul>
        </div>)
        return (
            <div>
                <LoadingOverlay
                    active={this.state.resetPasswordMode && unlockInfoLoader}
                    spinner
                    text='Fetching your details...'
                >
                <div className="grid padding-around font-size-medium center-element width-80">
                    <ReactTooltip delayHide={100} effect="solid" className="tooltipChangePwdColor" place="top" html={true} />
                    <div className="col-12">
                        <div className="flex margin-bottom-small header-container">
                            <div className="font-size-xlarge text-uppercase account-title"><strong>User Registration</strong></div>
                            {!this.state.resetPasswordMode &&
                            <div className="font-size-header">{loggedInUserInfo !== null && loggedInUserInfo.userId} |
                        <span className="hyperlink padding-left-smaller">
                                    <a href="/saml/logout">Logout</a>
                                </span>
                            </div>
                            }
                        </div>
                        <p className="account-creation-n"><strong>{this.state.resetPasswordMode ? "Set Password" : "Change Password"}</strong></p><br /><br />
                        <div className="col-6">
                            <div className="grid">
                                <p className="name"> Name </p>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p className="spiderman-webcrawler width-400-px"> {this.state.resetPasswordMode?
                                     fullName : 
                                     null !== loggedInUserInfo && loggedInUserInfo.firstName + ' ' + loggedInUserInfo.lastName}</p>
                            </div>
                            <div className="grid">
                                <p className="email-address"> Email Address </p>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p className="spiderman-webcrawler width-400-px"> {this.state.resetPasswordMode ? unlockedUserInfo.userId : null !== loggedInUserInfo && loggedInUserInfo.userId}</p>
                            </div>
                            <div className="grid">
                                <p className="company-name "> Company Name </p>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p className="web-crawler-inc width-400-px"> {this.state.resetPasswordMode ? unlockedUserInfo.companyName : null !== loggedInUserInfo && loggedInUserInfo.companyName}</p>
                            </div>
                        </div><br />
                        {!this.state.resetPasswordMode && <div className="grid">
                            <div className="col-6">
                                <p className="text-input-label">Enter Old Password <span className="text-required">*</span></p>
                                <div>
                                    <input type="password" placeholder="Type here..." name='oldPwd'
                                        value={this.state.oldPwd}
                                        onChange={this.onChangeField}
                                        required />
                                    {this.state.errorNonEngoldPwd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    {updatePwdRes.ResMessage === 'Failure' && updatePwdRes.errorType === "oldPasswordError"  &&
                                    <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle">
                                    </i>&nbsp;{updatePwdRes.errorMsg}</p>}
                                </div>
                            </div>
                        </div>}
                        <div className="grid">
                            <div className="col-12">
                        <p className="text-input-label">Enter New Password <span className="text-required">*</span></p>
                            <div className="col-12 flex">
                                        <div className="col-6">
                                        <input type="password" 
                                        placeholder="Type here..." name='newPwd' value={this.state.newPwd}
                                        onChange={this.onChangeField}
                                        required
                                            onBlur = {this.validateNewPassword.bind(this)}/>
                                          <BarLoader width={"50%"} color={'#84B135'} loading={newPwdValidateLoader} />
                                          </div>
                                        <i className=" fa fa-question-circle fa-lg margin-left" data-tip="<span> <span style='font-size : 15px !important'>Password must<br/></span><ul><li className='font-size-small'>Password should be 12 or more characters.</li><li>Contain at least 1 uppercase character</li><li>Contain at least 1 number</li><li>Contain at least 1 special character<br> ~!@#$%^&*()_+{}. No spaces allowed</li></ul></span>"
                                            style={{ color: "#84B135", marginTop: 8 }}></i>  
                            </div>                             
                                {this.state.errornewPwd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide New Password!</p>}
                                {this.state.errorNonEngnewPwd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                {this.state.invalidSpecialChar && !this.state.errornewPwd &&<p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Special characters allowed are ~!@#$%^&*()_+{}. Please update and retry!</p>}
                                {this.props.checkPwdComplexityRes !== "" && this.props.checkPwdComplexityRes !== "pass" &&
                                <p className="font-size-small text-color-red">
                                <i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;
                                  {this.renderPasswordRes()}
                                </p>
                                }
                                {updatePwdRes.ResMessage === 'Failure' && updatePwdRes.errorType === "newPasswordError"
                                  &&
                                    <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle">
                                    </i>&nbsp;{updatePwdRes.errorMsg}</p>}
                            </div>
                            </div>
                        <div className="grid">
                            <div className="col-6">
                                <p className="text-input-label">Re-enter New Password <span className="text-required">*</span></p>
                                <div>
                                    <input type="password" placeholder= {this.props.checkPwdComplexityRes === "" || this.props.checkPwdComplexityRes !== "pass" ? "" : "Type here..."} name='reEnterNewPwd' value={this.state.reEnterNewPwd} onChange={this.onChangeField} required
                                        onPaste={this.preventPasting}
                                        readOnly = {this.props.checkPwdComplexityRes === "" || this.props.checkPwdComplexityRes !== "pass"}/>
                                    {this.state.errorreEnterNewPwd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please Re-enter New Password!</p>}
                                    {!this.state.errorNonEngreEnterNewPwd && !this.state.errorreEnterNewPwd && !this.state.pwdMtched && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Password not match!</p>}
                                    {this.state.errorNonEngreEnterNewPwd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                </div>
                            </div>
                        </div>
                        {!this.state.resetPasswordMode ? <div className="padding-top">
                            <button className={this.state.enableSubmit && 
                            (this.props.checkPwdComplexityRes === "pass" || this.props.checkPwdComplexityRes === "") && !this.props.newPwdValidateLoader
                                ? "primary rectangle-back-button" :
                                "primary disabled rectangle-back-button"} onClick={this.updateNewPassword}>
                                <span className="back">Submit </span></button>
                        </div> : <div className="padding-top">
                            <button className={ this.state.newPwd !== '' && this.state.reEnterNewPwd !== "" 
                            && !this.props.newPwdValidateLoader && 
                            this.state.pwdMtched &&
                            (this.props.checkPwdComplexityRes === "pass" || this.props.checkPwdComplexityRes === "")
                            ? "primary rectangle-back-button" :
                                "primary disabled rectangle-back-button"} onClick={this.setNewPassword}>
                                <span className="back">Submit </span></button>
                        </div>}
                       {!this.state.resetPasswordMode && <FooterHyperlinks history={this.props.history} />} 
                    </div>
                </div>
                </LoadingOverlay>
                { !this.state.resetPasswordMode ? < DisclaimerDomainDialog dialogName="confirmation-dialog" accountTitle={"CHANGE PASSWORD CONFIRMATION"} accountText={accountText}
                    openDialog={updatePwdRes === "Success" ? true : false} history={this.props.history}
                    closeDialog={this.closeDialog.bind(this)} /> : < DisclaimerDomainDialog dialogName="confirmation-dialog" accountTitle={"SET PASSWORD CONFIRMATION"} accountText={accountText}
                    openDialog={setPwdRes === 'Success' ? true : false} history={this.props.history}
                            closeDialog={this.closeDialog.bind(this)} /> }
            </div>

        )
    }
}

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state) {
    return {
        loggedInUserInfo: state.changePwdReducer.loggedInUserInfo,
        newPwdValidateLoader:state.changePwdReducer.newPwdValidateLoader,
        checkPwdComplexityRes: state.changePwdReducer.checkPwdComplexityRes,
        updatePwdRes: state.changePwdReducer.updatePwdRes,
        unlockedUserInfo : state.changePwdReducer.unlockedUserInfo,
        unlockInfoLoader : state.changePwdReducer.unlockInfoLoader,
        setPwdRes: state.changePwdReducer.setPwdRes,
    }
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    getLoggedInUserInfo,
    validatePwd,
    removeError,
    resetChangePasswordValues,
    checkPwdComplexity,
    updatePassword,
    setPassword,
    getUnlockedUserInfo
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent)