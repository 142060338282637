//Async action creator
/***************************************************************************
 * File : encryptionAgreementAction.js
 * Description :-
 * 1. This file is used as Async action creator or Middleware
 * Steps : -
 * 1. Invoke API call
 * 2. Dispatch action type(same as mentioned in Reducer file e.g. CREATE_USER )
 *    in order to update state
 * @author Gauri Yadav
 **************************************************************************/

import {postApiCall,getApiCall} from '../utils/index'

export const ERROR_MARKETING_PREFERENCE = "ERROR_MARKETING_PREFERENCE"

export const createUser = (url, payLoad) => {
    return dispatch => {
      dispatch({ type : "LOADING" , loadingStarts : true})
      postApiCall(url, payLoad).then(res => {
        return res.json();
      }).then(res => {
        let resMsg = "" 
        if(res.emailAddress){
          resMsg = "Success";
        }
        dispatch({ type: "CREATE_USER", userCreated : resMsg})
      }
      ).catch(err => {
        // Do something for an error here
       window.open('/errorpage','_self')
       
      });
    }
  }

  export const clearSuccessMsg = () => {
    return dispatch => {
      dispatch({ type : "CLEAR_SUCCESS_MSG"})
    }
  }

  export const  getLoggedInUserInfo = (url) => {
    return dispatch => {
        getApiCall(url).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  dispatch(getUserByUserId(res.email))
             }).catch(err => {
              // Do something for an error here
              window.open('/errorpage','_self')
            });
            }
}

/**
 * 
 * @param {String: email address} id 
 * @returns 
 */
 export const getUserByUserId = (id) => {
  return dispatch => {
      postApiCall("/getUserByUserId", {emailAddress: id}).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_ACCOUNT_INFO", loggedInUserInfo: res });
           }).catch(err => {
              // Do something for an error here
             window.open('/errorpage','_self')
            });
      }
}

export const getJPLAS3Agreement = () => {
  return dispatch => {
      postApiCall("/getJPLALatestVersion").then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: "GET_JPLA_S3", jplaS3Agreement: res });
           }).catch(err => {
              // Do something for an error here
             window.open('/errorpage','_self')
            });
      }
}

export const acceptEncryption = (url, payload) =>{
  return dispatch =>{
    postApiCall(url, payload).then(res=>{
      return res.json();
    }).then(res => {
      dispatch({type : "ACCEPT_ENCRYPTION" , acceptEncrypt : 'Success'})
    }).catch(err => {
      // Do something for an error here
      window.open('/errorpage','_self')
    });
  }
}

export const resetAcceptEncryptValue = () => {
  return dispatch => { dispatch({type: "RESET_ACCEPT_ENCRYPTION"})}
}

/**
 * 
 * @param {*} payLoad 
 * @returns 
 */
export const submitMarketingPreferences = (payLoad) => {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type : "LOADING" , loadingStarts : true})
    postApiCall("/marketingPreferences", payLoad).then(resp => {
      if (resp.ok) {
        return resp.json()
          .then((responseData) => {
            return responseData;
          });
      }
      return resp.json()
        .then((error) => {
          return Promise.reject(error);
        });

    }).then(res => {
      dispatch({ type : "LOADING" , loadingStarts : false})
      return resolve(true) 
    }
    ).catch(err => {
      dispatch({ type: ERROR_MARKETING_PREFERENCE, data: "Marketing preference could not be saved."})
      dispatch({ type : "LOADING" , loadingStarts : false})
      return resolve(true)
    });
  })
}