//Async action creator
/***************************************************************************
 * File : cloudAction.js
 * Description :-
 * 1. This file is used as Async action creator or Middleware
 * Steps : -
 * 1. Invoke API call
 * 2. Dispatch action type(same as mentioned in Reducer file e.g. FETCH_CLOUD_PROVIDER, FETCH_CLOUD_REGIONS_AND_PRODUCTS )
 *    in order to update state
 * @author Gauri Yadav
 **************************************************************************/

import {postApiCall} from '../utils/index'
export const FETCH_CLOUD_PROVIDER = 'FETCH_CLOUD_PROVIDER';
export const FETCH_CLOUD_REGIONS_AND_PRODUCTS = 'FETCH_CLOUD_REGIONS_AND_PRODUCTS';

export const fetchCloudProvider = url => {
    return dispatch => {
      postApiCall(url,{}).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: FETCH_CLOUD_PROVIDER, cloudProviderList: res.GetCloudProviderRes.CloudProviderList });
           }).catch(err => {
            // Do something for an error here
           window.open('/errorpage','_self')
          });

    }
}


export const fetchCloudRegionsAndProducts = (url,payload) => {
    return dispatch => {
      postApiCall(url,payload).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: FETCH_CLOUD_REGIONS_AND_PRODUCTS, cloudDetail: res.GetCloudDetailsRes.CloudDetails });
           }).catch(err => {
            // Do something for an error here
           window.open('/errorpage','_self')
          });

    }
}

export const validateCloudData = (url,payload) => {
    return dispatch => {
        dispatch({ type : "VALIDATE_DATA_LOADER", validateCloudDataLoader : true})
        postApiCall(url,payload).then(res => {
            return res.json();
        }).then(res => {
            dispatch({type : 'VALIDATE_CLOUD_DATA', validateCloudDataAcctId : res.ValidateCloudDataReqRes})
            dispatch({ type : "VALIDATE_DATA_LOADER", validateCloudDataLoader : false})
        }).catch(err => {
            // Do something for an error here
          window.open('/errorpage','_self')
          });
    }
}

export const clearValidateData = () => {
   
    return dispatch => {
        dispatch({ type : "CLEAR_VALIDATE_DATA"})
      }
}