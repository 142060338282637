import React from "react"
import FooterHyperlinksComponent from "./FooterHyperlinksComponent"
import { isEnglishChar } from '../utils'
//Redux
import { fetchJobFunction, fetchTimeZones, fetchStates, getLoggedInUserInfo, CleanAcctInfo, updateAcctInformation,clearSuccessResponse,getSupplierInfo,clearSupplierInfo,getAccountMatch, getJPLAS3Agreement} from '../actions/accountcreationAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import EditAccountFieldsComponent from './EditAccountFieldsComponent';
import LoadingOverlay from 'react-loading-overlay';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";
import JPLADataAgreement from "./JPLADataAgreement";
import BarLoader from 'react-spinners/BarLoader';
import EloquaForm from "./EloquaForm"
import {ELQ_EDIT_URL} from '../utils/constants';
/*******************************************************
 * Screen - Account Creation
 * Description - This is the second step needed for User 
 *               Registration.
 * @author : Gauri Yadav
 ******************************************************/
var acctSetUpObj = {};
var applicationData = {};
var cloudObj = {};
class AccountCreationComponent extends React.Component {
    constructor(props) {
        super();
        this.navigateToAccountSetUp = this.navigateToAccountSetUp.bind(this);
        this.validateMandatoryFieldsAndError = this.validateMandatoryFieldsAndError.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.navigateToEncryptionAgreementPage = this.navigateToEncryptionAgreementPage.bind(this);
        this.checkForEngChar = this.checkForEngChar.bind(this);
        this.checkforValidName = this.checkforValidName.bind(this);
        this.renderJobFunction = this.renderJobFunction.bind(this);
        this.renderTimeZone = this.renderTimeZone.bind(this);
        this.persistAccountCreationData = this.persistAccountCreationData.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
        this.editAcctInfo = this.editAcctInfo.bind(this);
        this.getSupplierInfo = this.getSupplierInfo.bind(this);
        this.renderSupplierApps = this.renderSupplierApps.bind(this);
        this.getCommaSeperatedSupplierAppList = this.getCommaSeperatedSupplierAppList.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleUpdateJplaUserAttributr = this.handleUpdateJplaUserAttributr.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.state = {
            firstName: "",
            lastName: "",
            companyName: "",
            jobTitle: "",
            jobFunc: -1,
            compUrl: "",
            phoneCode:"",
            phoneNum: "",
            phoneExt: "",
            fax: "",
            add1: "",
            add2: "",
            city: "",
            stateProvince: -1,
            stateName : "- Please Specify -",
            country : "",
            countryCode : "",
            zip: "",
            timeZone: -1,
            ipAdd: "",
            authCode: "",
            serialNum:"",
            errorfirstName: false,
            errorlastName: false,
            errorcompanyName: false,
            errorjobFunc: false,
            errorphoneNum: false,
            erroradd1: false,
            errorcity: false,
            errorstateProvince: false,
            errorzip: false,
            errortimeZone: false,
            prepopulate: false,
            errorNonEngfirstName: false,
            errorNonEnglastName: false,
            errorNonEngcompanyName: false,
            errorNonEngjobTitle: false,
            errorNonEngcompUrl: false,
            errorNonEngphoneNum: false,
            errorNonEngphoneExt: false,
            errorNonEngfax: false,
            errorNonEngadd1: false,
            errorNonEngadd2: false,
            errorNonEngcity: false,
            errorNonEngzip: false,
            errorNonEngtimeZone: false,
            errorNonEngipAdd: false,
            errorNonEngauthCode: false,
            errorNonNumphoneNum : false,
            errorInvalidcompanyName : false,
            enableNextButton: false,
            isChecked: false,
            editMode : false,
            supplierMode : false,
            supplierAppsList : [],
            openDialog : false,
            addressMatch : false,
            sapAcctId : null,
            elevateCircleCode: "",
			userType: null,
            eloQua: [],
            modalOpen: false,
            isActivationAllowed : false,
        }
    }

    componentDidMount = () => {
        if(undefined !== this.props.location.state){
            applicationData = this.props.location.state.applicationData;
            cloudObj = applicationData.cloudProviderObj
        }

        window.scrollTo(0, 0);
        const url = '/getJobFunctions';
        if (this.props.location.pathname.indexOf('editaccountinformation') < 0) {
            this.props.CleanAcctInfo();
            const getTimeZoneURL = "/getTimezones";
            const getStateURL = "/getStates";
            let countryCode = "";
            let elevatedCircleCode = "";
            let sapAccountId = ""
            let userType = ""
            let phoneCodeField = []
            let eloQua = [];
            if (this.props.location.state !== undefined) {
                acctSetUpObj = this.props.location.state.applicationData.accountSetUpObj;
                countryCode = acctSetUpObj.countryCode; 
                elevatedCircleCode = acctSetUpObj.elevatedCircleCode 
                phoneCodeField = acctSetUpObj.phoneCode && acctSetUpObj.phoneCode.split(", ")
				sapAccountId = acctSetUpObj.sapAccountId
                userType = acctSetUpObj.userType
                eloQua = this.props.location.state.applicationData && this.props.location.state.applicationData.accountCreationObj.eloQua
            }

            this.setState({
                phoneCode : phoneCodeField && phoneCodeField[0],
                countryCode : countryCode,
                elevatedCircleCode: elevatedCircleCode,
                sapAccountId: sapAccountId,
                userType: userType,
                eloQua: eloQua
			})
            var countryBasedPayLoad = {
                "countryCode": countryCode
            }
            this.props.fetchTimeZones(getTimeZoneURL, countryBasedPayLoad);
            this.props.fetchStates(getStateURL, countryBasedPayLoad);
        }else{
            this.editAcctInfo(); //Edit account Info flow
        }
        this.props.fetchJobFunction(url);
        let parsedAcctSetUpObj = applicationData && applicationData.accountSetUpObj;
        (parsedAcctSetUpObj !== undefined && !parsedAcctSetUpObj.isGuestUserReq) && this.props.getJPLAS3Agreement();
        
        var parsedAccountCreationObj;

        if (applicationData !== undefined && 
            applicationData.accountCreationObj !== null &&
            applicationData.accountSetUpObj !== null) {
                parsedAccountCreationObj = applicationData.accountCreationObj;
            let enableNxtBtn = true;
            let acctCreationObjIsEmpty = true;
            for (let key in parsedAccountCreationObj) {
                if (parsedAccountCreationObj.hasOwnProperty(key)) {
                    acctCreationObjIsEmpty = false;
                    let val = parsedAccountCreationObj[key];
                    if (key === "firstName" || key === "lastName" || key === "companyName" || key === "compUrl" || key === "jobTitle" || key === "jobFunc"
                        || key === "add1" || key === "add2" || key === "phoneExt" || key === "fax" || key === "timeZone"
                        || key === "city" || key === "stateProvince" || key === "zip" || key === "phoneNum" || key === "stateName" || key === "elevatedCircleCode" || key ==="isActivationAllowed") {
                        this.setState({ [key]: val });
                    }
                    if(key === "phoneCode"){
                        this.setState({phoneCode : parsedAccountCreationObj.phoneCode.split(", ")[0]})
                    }
                }
            }
            for (let key in parsedAccountCreationObj) {
                if (parsedAccountCreationObj.hasOwnProperty(key)) {
                    let val = parsedAccountCreationObj[key];
                    if ((key === "firstName" || key === "lastName" || key === "zip" || key === "add1" ||
                        key === "phoneNum" || key === "city") && val.toString().trim() === "") {
                        enableNxtBtn = false;
                        break;
                    }
                    if ((key === "stateProvince" || key === "jobFunc" || key === "timeZone") && val.toString() === "-1") {                        
                        enableNxtBtn = false;
                        break;
                    }
                    if (key === "companyName" && val.toString().trim() === "") {
                        let persistPersonalUse = parsedAccountCreationObj.personalUse;
                        if (!persistPersonalUse) {
                        enableNxtBtn = false;
                        break;
                        }else{
                                this.setState({ isChecked: true })
                            }
                    }
                    else if(key === "companyName" && val.toString().trim() !== ""){
                        this.checkforValidName("companyName",val)
                    }
                }
            }
           
        if (enableNxtBtn && !acctCreationObjIsEmpty) {
            this.setState({
                enableNextButton: true
            })
        }
        }
        if (applicationData.accountSetUpObj !== undefined) {
             parsedAccountCreationObj = applicationData.accountCreationObj;
            acctSetUpObj = applicationData.accountSetUpObj;
            let company = acctSetUpObj.companyName;
            let isLabUser = acctSetUpObj.isLabUser;
            
            if(parsedAccountCreationObj !== null && parsedAccountCreationObj.companyName !== undefined){
                company = parsedAccountCreationObj.companyName;
                if(!isLabUser && company === ""){
                    this.setState({
                        isChecked : false
                    })
                    this.validateMandatoryFieldsAndError();
                }
            }

            let userType = acctSetUpObj.loggedInUser;
            let authCode = acctSetUpObj.authCode;
            let countryName = acctSetUpObj.countryName;
            this.setState({
                companyName : company,
                country : countryName
            })
        if(userType === "Supplier"){
            this.getSupplierInfo(authCode);
        }
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.loggedInUserInfo !== this.props.loggedInUserInfo){
            if(this.props.loggedInUserInfo && null !== this.props.loggedInUserInfo && this.props.loggedInUserInfo.UserId !== null){
                const getTimeZoneURL = "/getTimezones";
                const getStateURL = "/getStates";
                let countryCode = "";
                if (this.props.loggedInUserInfo.country !== null) {
                    countryCode = this.props.loggedInUserInfo.country;
                }
                var countryBasedPayLoad = {
                    "countryCode": countryCode
                }
                
                let FirstName, LastName, CompanyName, JobTitle, JobFunction, CompanyUrl, PhoneNumber, FaxNumber
                ,AddressLine1, AddressLine2, City, State, ZipCode, Timezone,phoneExtension,PhoneCode;
                
                let loggedInUserInfo = this.props.loggedInUserInfo;
               
                    FirstName = loggedInUserInfo.firstName === null ? 'N/A' : loggedInUserInfo.firstName
                    LastName = loggedInUserInfo.lastName === null ? 'N/A' : loggedInUserInfo.lastName
                    CompanyName = loggedInUserInfo.companyName === null ? 'N/A' : loggedInUserInfo.companyName
                    JobTitle = loggedInUserInfo.jobTitle === null ? 'N/A' : loggedInUserInfo.jobTitle
                    JobFunction = loggedInUserInfo.jobFunction === null ? 'N/A' : loggedInUserInfo.jobFunction
                    CompanyUrl = loggedInUserInfo.companyUrl === null ? 'N/A' : loggedInUserInfo.companyUrl
                    if(loggedInUserInfo !== null){
                        if(loggedInUserInfo.phoneNumber === null){
                            PhoneNumber = 'N/A'
                            phoneExtension = 'N/A'
                        }

                        else if(loggedInUserInfo.phoneNumber && loggedInUserInfo.phoneNumber.toLowerCase().indexOf('ext#') > 0){
                            let phoneField = loggedInUserInfo.phoneNumber.toLowerCase().split("ext#").filter(data => data.trim() !== "");

                                if(phoneField.length > 1){
                                    PhoneNumber = phoneField[0].trim()
                                    phoneExtension = phoneField[1].trim()
                                }
                                else{
                                    PhoneNumber = phoneField[0].trim()
                                    phoneExtension = ""
                                }

                               let PhoneSplit = PhoneNumber.split(" ").filter(data => data.trim() !== "")
                                if(PhoneSplit.length > 1){
                                    PhoneCode = PhoneSplit[0].trim()
                                    PhoneNumber = PhoneSplit[1].trim()
                                }
                                else{
                                    PhoneCode = ''
                                    PhoneNumber = PhoneSplit[0].trim()
								}
                            }
                        else{
                            PhoneNumber = loggedInUserInfo.phoneNumber;
                            phoneExtension = ''
                            PhoneCode = ""
                       }
                }
                    FaxNumber = loggedInUserInfo.faxNumber === null ? 'N/A' : loggedInUserInfo.faxNumber
                    AddressLine1 = loggedInUserInfo.address1 === null ? 'N/A' : loggedInUserInfo.address1
                    AddressLine2 = loggedInUserInfo.address2 === null ? 'N/A' : loggedInUserInfo.address2
                    City = loggedInUserInfo.city === null ? 'N/A' : loggedInUserInfo.city
                    State = loggedInUserInfo.state === null ? 'N/A' : loggedInUserInfo.state
                    ZipCode = loggedInUserInfo.zip === null ? 'N/A' : loggedInUserInfo.zip
                    Timezone = loggedInUserInfo.timeZone === null ? 'N/A' : loggedInUserInfo.timeZone
                    
                //Setting state

                let logendInUserObj = {
                    firstName : FirstName,
                    lastName : LastName,
                    companyName : CompanyName,
                    jobTitle : JobTitle,
                    jobFunc : JobFunction,
                    compUrl : CompanyUrl,
                    phoneCode : PhoneCode,
                    phoneNum : PhoneNumber,
                    fax : FaxNumber,
                    add1 : AddressLine1,
                    add2 : AddressLine2,
                    city : City,
                    stateProvince : State,
                    zip : ZipCode,
                    timeZone : Timezone,
                    phoneExt : phoneExtension
                }

                this.setState(logendInUserObj)
				this.props.fetchTimeZones(getTimeZoneURL, countryBasedPayLoad);
                this.props.fetchStates(getStateURL, countryBasedPayLoad);
            }
        }
        if(prevProps.supplierInfo.SupplierProfile !== this.props.supplierInfo.SupplierProfile && this.props.supplierInfo !== {}){
            let getStateURL = "/getStates";
            let supplierInfo = this.props.supplierInfo.SupplierProfile;
            let supplierApps = this.props.supplierInfo.SupplierApps;
            let {companyName,add1,add2,city,stateProvince,zip,country,countryCode} = this.state
            if (applicationData.accountSetUpObj !== null) {
                acctSetUpObj = applicationData.accountSetUpObj;
                let countryCode = acctSetUpObj.countryCode;
                if(countryCode !== supplierInfo.countryCode){
                    acctSetUpObj.countryCode = supplierInfo.countryCode
                    acctSetUpObj.countryName = supplierInfo.countryName
                    this.props.fetchStates(getStateURL, {countryCode: supplierInfo.countryCode});
                }
            }
            this.setState({
                companyName : null !== supplierInfo.companyName ? supplierInfo.companyName : companyName,
                add1 : null !== supplierInfo.addressLine1 ? supplierInfo.addressLine1 : add1,
                add2 : null !== supplierInfo.addressLine2 ? supplierInfo.addressLine2 : add2,
                city : null !== supplierInfo.city ? supplierInfo.city : city,
                stateProvince : null !== supplierInfo.state ? supplierInfo.state : stateProvince,
                zip : null !== supplierInfo.zip ? supplierInfo.zip : zip,
                country : null !== supplierInfo.countryName ? supplierInfo.countryName : country,
                countryCode : null !== supplierInfo.countryCode ? supplierInfo.countryCode : countryCode,
                supplierMode : true,
                supplierAppsList : supplierApps
            })
        }
        if(prevProps.states !== this.props.states){
            if(this.props.states !== "No States Available" && this.state.stateProvince === ""){
                this.setState({
                    enableNextButton : false
                })
            }
        }
        if(prevProps.accountMatchResponse !== this.props.accountMatchResponse){
            this.setState({
                enableNextButton : true
            })
            this.handleNextBtn()
        }
    }

    /************************************************************************
     * Triggered : when user is directed from SAML login page
     * Description  : This function is used to prepopulate Account information 
     *                saved by user while doing user registration.
     * 
     ************************************************************************/
    editAcctInfo(){
        this.setState({editMode : true});
        this.props.getLoggedInUserInfo('/getLoggedInUserInfo');
    }

    handleModalOpen = () => {
        console.log("inside modal open");
        this.setState({
            modalOpen: !this.state.modalOpen,
        })
    }

    handleUpdateJplaUserAttributr = () => {
        this.setState({
            modalOpen: !this.state.modalOpen,
            isActivationAllowed: !this.state.isActivationAllowed
        });
    }

    handleChecked = () => {
        this.setState({
            isActivationAllowed: !this.state.isActivationAllowed
        });
    }
    
    /**********************************************************
     * Triggered : On click of Back button
     * Description : This function is used to navigate back to 
     *               Account Set Up page.
     * 
     *********************************************************/
    navigateToAccountSetUp(emailAddress, country,userType) {
        let accountCreationObj = this.persistAccountCreationData(emailAddress, country,userType);
        if (null != applicationData.accountSetUpObj && 
            applicationData.accountSetUpObj.loggedInUser  === 'Cloud User') {
                applicationData.accountSetUpObj = acctSetUpObj;
                applicationData.accountCreationObj = accountCreationObj
                applicationData.isNavigatedBackFromAcctCreation = 'true';
                this.props.history.push({
                    pathname: '/cloudusercreation',
                    state: { applicationData : applicationData },
                    prevPath: this.props.location.pathname
                  })
        } else {
            applicationData.accountSetUpObj = acctSetUpObj;
            applicationData.accountCreationObj = accountCreationObj
            applicationData.isNavigatedBackFromAcctCreation = 'true';

            this.props.history.push({
                pathname: '/validateRegistration',
                state: { applicationData : applicationData },
                prevPath: this.props.location.pathname
              })
        }

    }

    /*******************************************************************
     * Triggered : On change of of input fields
     * Description : This function is used to perform below operations:-
     *               1) Validate whether all mandatory fields are filled, 
     *                  else display error message.
     *               2) Once all mandatory fields are filled, navigate to
     *                  Encryption agreement page.
     * 
     *******************************************************************/
    validateMandatoryFieldsAndError() {
        let { errorfirstName, errorlastName, errorcompanyName, errorjobFunc, errorphoneNum, erroradd1, errorcity, errorstateProvince, errorzip, errortimeZone, errorNonEngfirstName, errorNonEnglastName, errorNonEngcompanyName,
            errorNonEngjobTitle, errorNonEngcompUrl, errorNonEngphoneNum, errorNonEngphoneExt, errorNonEngfax, errorNonEngadd1, errorNonEngadd2, errorNonEngcity, errorNonEngzip, errorNonEngtimeZone, errorNonEngipAdd, errorNonEngauthCode,errorNonNumphoneNum,errorInvalidcompanyName,
            firstName, lastName, companyName, jobFunc, phoneNum, add1, city, stateProvince, zip, timeZone
        } = this.state
        let {states} = this.props;
        let allMandatoryFieldsFilled = true;
        if (errorfirstName || errorlastName || errorjobFunc || errorphoneNum || erroradd1 || errorcity || errorstateProvince || errorzip || errortimeZone || errorNonEngfirstName || errorNonEnglastName || errorNonEngjobTitle || errorNonEngcompUrl || errorNonEngphoneNum || errorNonEngphoneExt || errorNonEngfax || errorNonEngadd1 || errorNonEngadd2 || errorNonEngcity || errorNonEngzip || errorNonEngtimeZone || errorNonEngipAdd || errorNonEngauthCode || errorNonNumphoneNum || firstName === "" || 
        (states !== null && states !== "No States Available" && (stateProvince === "-1" || stateProvince === -1)) || lastName === "" || jobFunc === "-1" || jobFunc === -1 || phoneNum === "" || add1 === "" || city === "" || zip === "" || timeZone === "-1" || timeZone === -1 ||
            (companyName === "" && !this.state.isChecked)
            || errorNonEngcompanyName || errorcompanyName || errorInvalidcompanyName) {
            allMandatoryFieldsFilled = false;
        }
        //enable nxt button if all mandatory fields are full    
        if (allMandatoryFieldsFilled) {
            this.setState({
                enableNextButton: true
            })
        }
        else {
            this.setState({
                enableNextButton: false
            })
            return
        }
    }

    /**********************************************************
     * Triggered : On click of Next button
     * Description : This function is used to navigate to 
     *               Encryption Agreement page.
     * 
     *********************************************************/
    navigateToEncryptionAgreementPage(emailAddress, country, companyName,phoneCode) {
        // this.props.clearSupplierInfo();
        let accountCreationObj = this.persistAccountCreationData(emailAddress, country, companyName,phoneCode);
        applicationData.accountSetUpObj = acctSetUpObj;
        applicationData.accountCreationObj = accountCreationObj
        applicationData.cloudProviderObj = cloudObj
        this.props.history.push({
            pathname: '/encryptionagreement',
            state: { applicationData : applicationData }
          })
    }

    persistAccountCreationData(emailAddress, country,userType,phoneCode) {
        var accountCreationObj = {};
        accountCreationObj.emailAddress = emailAddress;
        accountCreationObj.emailAddress = emailAddress;
        accountCreationObj.userType = this.state.userType; //account set up page checkbox
        accountCreationObj.companyId = ""; //account set up page
        accountCreationObj.firstName = this.state.firstName;
        accountCreationObj.middleInitials = "";
        accountCreationObj.lastName = this.state.lastName;
        accountCreationObj.companyName = this.state.companyName;
        accountCreationObj.compUrl = this.state.compUrl;
        accountCreationObj.add1 = this.state.add1;
        accountCreationObj.add2 = this.state.add2;
        accountCreationObj.addressLine3 = "";
        accountCreationObj.city = this.state.city;
        accountCreationObj.stateProvince = this.props.states === 'No States Available' ? '' : this.state.stateProvince;
        accountCreationObj.stateName = this.props.states === 'No States Available' ? '' : this.state.stateName;
        accountCreationObj.zip = this.state.zip
        accountCreationObj.country = country;
        accountCreationObj.theater = "";
        // if(phoneCode !== undefined && phoneCode.length > 10 && this.state.phoneCode !== undefined){
        //     accountCreationObj.phoneCode = this.state.phoneCode
            
        //     }else if(phoneCode === undefined){
        //     accountCreationObj.phoneCode = this.state.phoneCode
            
        //     }else{
        //     accountCreationObj.phoneCode = phoneCode
            
        //     }
        accountCreationObj.phoneCode = this.state.phoneCode
        accountCreationObj.phoneNum = this.state.phoneNum;
        accountCreationObj.phoneExt = this.state.phoneExt
        accountCreationObj.fax = this.state.fax;
        accountCreationObj.password = "";
        accountCreationObj.serialNumber = "";
        accountCreationObj.source = "";
        accountCreationObj.createdBy = "";
        accountCreationObj.createdDate = "";
        accountCreationObj.modifiedBy = "";
        accountCreationObj.modifiedDate = "";
        accountCreationObj.primaryRole = this.state.jobFunc;
        accountCreationObj.encryptionRequest = "";
        accountCreationObj.authorizationCode = "";
        accountCreationObj.fullName = "";
        accountCreationObj.encryptionRequestDate = "";
        accountCreationObj.timeZone = this.state.timeZone;
        accountCreationObj.publicDomainFlg = ""; //account set up page
        accountCreationObj.cloudProviderId = ""; //AWS || Azure || GCP
        accountCreationObj.cloudAccountId = ""; // Acct Id or Subs Id
        accountCreationObj.cloudInstanceId = ""; // Instance Id
        accountCreationObj.cloudProductCode = "";
        accountCreationObj.ipAddress = "";
        accountCreationObj.appName = "";
        accountCreationObj.personalUse = this.state.isChecked;
        //Additional attributes that will be used for persisting data
        accountCreationObj.jobTitle = this.state.jobTitle;
        accountCreationObj.jobFunc = this.state.jobFunc;
        accountCreationObj.phoneExt = this.state.phoneExt;
        accountCreationObj.suppApps = this.getCommaSeperatedSupplierAppList();
        accountCreationObj.sapAcctId = this.state.sapAcctId
        accountCreationObj.sapAccountId = this.state.sapAccountId
		//This attribute added to send data as eloqua form
        accountCreationObj.eloQua = this.state.eloQua;
        accountCreationObj.isActivationAllowed = this.state.isActivationAllowed;
        accountCreationObj.jplaAgreement = this.props.jplaS3Agreement;
        return accountCreationObj;
    }

    /*****************************************************************
     * Triggered : On change of any Input form field
     * Description : This function is used to update the field 
     *               value and instantly remove the error associated with
     *               that field.
     ******************************************************************/
    onChangeField(event) {
        this.setState({ [event.target.name]: event.target.value });
        var errorString = "error" + event.target.name;
        if (event.target.value.trim() === "" || event.target.value === "-1" || event.target.value === -1) {
            this.setState({ [errorString]: true }, () => {
                this.validateMandatoryFieldsAndError();
            });
        } else {
            this.setState({ [errorString]: false }, () => {
                this.validateMandatoryFieldsAndError();
            });
        }
        if(event.target.name === "stateProvince"){
            let index = event.nativeEvent.target.selectedIndex;
            let label = event.nativeEvent.target[index].text;
            this.setState({
                stateName : label
            })
        }
        this.checkForEngChar(event);
        this.checkforNumChar(event)

        if(event.target.name === "companyName"){
        this.checkforValidName(event.target.name,event.target.value);
        }
    }

    /*****************************************************************
     * Triggered : On blur of any Input form field
     * Description : This function is used to validate whether input provided
     *               is in english or not.
     ******************************************************************/
    checkForEngChar(event) {
        var isFieldContainsEnglishCharacter = isEnglishChar(event);
        var errorString = "errorNonEng" + event.target.name;
        if (!isFieldContainsEnglishCharacter) {
            this.setState({ [errorString]: true });
        } else {
            this.setState({ [errorString]: false });
        }
    }


    checkforNumChar(event){
        if(event.target.name === "phoneNum"){
        let val = event.target.value
        let errorString = "errorNonNum" + event.target.name;
        // eslint-disable-next-line
        var numbers = /^[0-9]*$/;
        if(val !== null && (val.match(numbers) || val.length === 0)){
            this.setState({[errorString] : false})
        }
        else{
            this.setState({[errorString] : true})
        }
    }
    }

    checkforValidName(name,value){
        if(name === "companyName"){
        // let val = event.target.value
        let errorString = "errorInvalid" + name;
        let invalidCompanyName = ["-","|","*","0","N/A","NA","NO","NONAME","NONE","PRIVATE","PRIVATE ACCOUNT","PRIVATE CONSULTANT","PRIVATE CORP.","PRIVATE PERSON","TEST","PERSONAL","PERSONAL USE","PERSONAL ACCOUNT","PERSONAL LAB","PERSONAL PC"]
        value = value.toUpperCase().trim()
        if(value !== "" && invalidCompanyName.includes(value)){
            this.setState({ [errorString]: true }, () => {
                this.validateMandatoryFieldsAndError();
            });
        }
        else{
            this.setState({ [errorString]: false }, () => {
                this.validateMandatoryFieldsAndError();
            });
        }
    }
    }

    /*****************************************************************
     * Function : 	 renderJobFunction()
     * Description : This function is used to generate country dropdown
	 * 				 options with list fetched from api.
     ******************************************************************/
    renderJobFunction(jobType) {
        return jobType.map((jobType, index) => {
            return <option key={"jobType" + index} value={jobType} >{jobType}</option>
        })
    }


    renderTimeZone(timeZones) {
        return timeZones.map((timeZone, index) => {
            return <option key={"timeZone" + index} value={timeZone} >{timeZone}</option>
        })
    }

    renderState(states) {
        if(states !== "No States Available"){
        return states.map((state, index) => {
            return <option key={"state" + index} value={state.stateCode} >{state.stateName}</option>
        })
    }
    }

    renderPhoneCode(phoneCode) {
        let {loggedInUserInfo} = this.props
        let field = phoneCode && phoneCode !== null && phoneCode.split(', ')
        if(field && field.length > 1){
        return <select name="phoneCode" value={this.state.phoneCode} onChange={this.onChangeField} className="col-5 no-margin-top select-height">
        {field && field.map((state, index) => {
            return <option key={"state" + index} value={state} >{(loggedInUserInfo && loggedInUserInfo.country) || this.state.countryCode}&nbsp;{state !== "" && '+'}{state}</option>
        })
        }</select>
         }
         else{
            return <label type="text" className="col-3 label-input-text">{(loggedInUserInfo && loggedInUserInfo.country) || this.state.countryCode} &nbsp;{this.state.phoneCode && this.state.phoneCode !== "" && !this.state.phoneCode.includes('+') ? '+' : ''}{this.state.phoneCode}</label>
         }
    }

    /**
     * Description : API /getSupplierInfo is called if the loggedInUser type is Supplier
     */
    getSupplierInfo(authCode){
        let payload = {
            authorizationCode : authCode
        }
        this.props.getSupplierInfo('/getSupplierInfo',payload);
    }
    onCheckboxClick(event) {
        this.setState({ isChecked: event.target.checked },
            () => {
                if (this.state.isChecked) {
                    this.setState({
                        companyName: "",
                        errorNonEngcompanyName: false,
                        errorcompanyName: false,
                        errorInvalidcompanyName : false
                    }, () => {
                        this.validateMandatoryFieldsAndError();
                    })
                } else {
                    this.validateMandatoryFieldsAndError();
                }

            })
    }

    //Triggered on click of Save button of Edit Account Info page
    updateAcctInfo(countryCode){
        var updateAcctReqPayload = {
                "firstName" : this.state.firstName,
                "lastName" : this.state.lastName,
                "companyName" : this.state.companyName,
                "jobTitle" : this.state.jobTitle,
                "jobFunction" : this.state.jobFunc,
                "phoneNumber" : this.state.phoneCode + ' ' + this.state.phoneNum + ' Ext# ' +this.state.phoneExt,
                "faxNumber" : this.state.fax,
                "addressLine1" : this.state.add1,
                "addressLine2" : this.state.add2,
                "city" : this.state.city,
                "state" : this.state.stateProvince,
                "zip" : this.state.zip,
                "countryCode" : countryCode,
                "timeZone" : this.state.timeZone,
                "isEvaluationUserReq" : false,
                "isLabUserReq" : false
        }

        if(this.props.loggedInUserInfo) {
            updateAcctReqPayload.userId = this.props.loggedInUserInfo.userId
            updateAcctReqPayload.fullName = this.state.fullName
            updateAcctReqPayload.companyUrl = this.state.compUrl
            updateAcctReqPayload.userType = this.props.loggedInUserInfo.userType
            updateAcctReqPayload.emailAddress = this.props.loggedInUserInfo.userId
            updateAcctReqPayload.incomingSource = this.props.loggedInUserInfo.incomingSource

            this.props.updateAcctInformation(`${"/editUserAccount"}/${this.props.loggedInUserInfo.userId}`,updateAcctReqPayload);
        } else {
            this.props.updateAcctInformation('/updateAccountInfo',updateAcctReqPayload);
        }
    }
    onKeyPressEvent(event){
       if((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || event.charCode === 32){
           return true
       }
       else{
        event.preventDefault();
       return false
       }
    }
    closeDialog(){
        this.props.clearSuccessResponse()
    }
    showComplianceDialog(element){
        if(element){
        this.setState({
            openDialog : true
        })
    }
    }
    closeComplianceDialog(){
        this.setState({
            openDialog : false
        })
    }
    proceedDialog(){
        if (this.props.location.state !== undefined) {
            let acctObj = this.props.location.state.applicationData.accountSetUpObj;
            this.navigateToEncryptionAgreementPage(acctObj.emailAdd,acctObj.countryName,acctObj.companyName,acctObj.phoneCode)
        }
    }

    checkForAccountMatch(){
        let {add1,countryCode,stateProvince,zip,companyName} = this.state
        let payload = {
                "address_l1" : add1,
                "country" : countryCode,    
                "region"    : stateProvince,
                "post_code1" : zip,
                "name1" : companyName
            }
        this.props.getAccountMatch("/addressValidation",payload)
        this.setState({
            enableNextButton : false
        })
    }
    
    checkCompliance(){
        let {loggedInUser, emailAdd, countryName,companyName, phoneCode} = acctSetUpObj
        if(loggedInUser === "Base User"){
            this.navigateToEncryptionAgreementPage(emailAdd,countryName,companyName,phoneCode)
        }
        else{
            this.showComplianceDialog('compliance-dialog')
        }
    }
    handleNextBtn(){
        let {accountMatchResponse} = this.props

        if(accountMatchResponse.T_MATCHED_BPS.length === 0){
            this.checkCompliance()
        }
        else if(accountMatchResponse.T_MATCHED_BPS.length > 0){
            this.setState({
                addressMatch : true
            })
        }
    }

    closeAddressDialog(input){
        let {ADDRESS_L1,ADDRESS_L2,NAME1,CITY,REGION,COUNTRY,POST_CODE1} = this.props.accountMatchResponse.ADDRESS_DATA_OUT[0]
        let {PARTNER} = this.props.accountMatchResponse.T_MATCHED_BPS[0]
        this.setState({
            addressMatch : false
        })
        if(input === "Y"){
            this.setState({
                add1 : ADDRESS_L1,
                add2 : ADDRESS_L2,
                companyName : NAME1,
                city : CITY,
                stateProvince : REGION,
                countryCode : COUNTRY,
                zip : POST_CODE1,
                sapAcctId : PARTNER
            },() => this.checkCompliance())
        }
        else if(input === "N"){
            this.checkCompliance()
        }
    }
    //This function is used to render supplier app checkboxes
    renderSupplierApps(suppAppList){
            return suppAppList.map((suppApp, index) => {
                return <label key={"suppApp" + index}  className="container ">
                                        <input name="supplierApp" type="checkbox" value={suppApp.name} ref="supplierApp"></input>
                                        <span className="checkmark"></span>
                                        <label style={{ fontSize : '13px'}}>{suppApp.name}</label>
                                    </label>
            })
    }

     //This function is used to generate comma seperated list of selected Supplier Apps
    getCommaSeperatedSupplierAppList(){
        let parentNode = "";
        let suppAppCheckboxlist = "";
        if(this.refs.supplierApp !== undefined){
        parentNode = this.refs.supplierApp.parentNode.parentNode;
        suppAppCheckboxlist  = parentNode.getElementsByTagName('input')
        }
        var suppAppList = "";
        if(suppAppCheckboxlist !== undefined && suppAppCheckboxlist !== null && suppAppCheckboxlist.length > 0){
            for(var i=0 ; i< suppAppCheckboxlist.length ; i++ ){
                
                if(suppAppCheckboxlist[i].checked){
                    suppAppList = suppAppList + suppAppCheckboxlist[i].value + ",";
                }
            }
            suppAppList = suppAppList.replace(/,\s*$/, "");
        }
        return suppAppList;
    }

    /**
     * This is used to maintain eloqua checkbox
     * @param {checkbox event object} e 
     */
    onChangeEloqua = (e) => {
        let eloqua = this.state.eloQua? [...this.state.eloQua] : [];
        if(e.target.checked) {
            eloqua.push(e.target.name)
            this.setState({ eloQua: eloqua })
        } else {
            eloqua.splice(eloqua.indexOf(e.target.name), 1)
            this.setState({ eloQua: eloqua })
        }
    }

    render() {
        const { timeZones, states, loggedInUserInfo,savingLoader} = this.props;
        let emailAddress = "", country = "", isLabUser = "",persistedPersonalUse = false,authCode = "",serialNumber="",companyName="",userType="",phoneCode=null, countryCode = "";
        if (applicationData.accountSetUpObj !== undefined) {
            acctSetUpObj = applicationData.accountSetUpObj;
            emailAddress = acctSetUpObj.emailAdd;
            country = acctSetUpObj.countryName;
            countryCode = acctSetUpObj.countryCode
            phoneCode = acctSetUpObj.phoneCode
            isLabUser = acctSetUpObj.isLabUser;
            authCode = acctSetUpObj.authCode;
            serialNumber = acctSetUpObj.serialNumber;
            companyName = acctSetUpObj.companyName;
            userType = acctSetUpObj.loggedInUser;
        }
        if(applicationData.accountCreationObj !== undefined){
        persistedPersonalUse = applicationData.accountCreationObj.personalUse;
        }
        
        //Edit Acct Info fields | Start
        let  CountryName,Authcode ;
        if(loggedInUserInfo && loggedInUserInfo !== null){
            CountryName = loggedInUserInfo.countryName === null ? 'N/A' : loggedInUserInfo.countryName
            Authcode = loggedInUserInfo.authCode === null ? 'N/A' : loggedInUserInfo.authCode
        }
        let isPartner = loggedInUserInfo && loggedInUserInfo !== null && (loggedInUserInfo.userType === "Distributor" || loggedInUserInfo.userType === "JPartner") ? true : false
        //Eloqua varialbles
        let {eloQua}  = this.state
        let parsedAcctSetUpObj = applicationData && applicationData.accountSetUpObj;
        return (
            <div>
                <LoadingOverlay
                    active={savingLoader}
                    spinner
                    text='Saving Account Information...'
                >
                <div  style = {{ overflowY : 'auto', height : '600px'}} className="grid padding-around font-size-medium center-element width-80">
                    <div className="col-12">
                        <div className="flex margin-bottom-small header-container">
                        {!this.state.editMode && 
                        <div className="font-size-xlarge text-uppercase"><strong>User Registration</strong></div>}
                        {//Edit Account Info titles
                        }
                        {this.state.editMode && 
                        <div className="font-size-xlarge text-uppercase account-title"><strong>Edit Account Information</strong></div>}
                        { this.state.editMode && 
                        <div className="font-size-header">{loggedInUserInfo && loggedInUserInfo !== null && loggedInUserInfo.userId} |  
                        <span className="hyperlink padding-left-smaller">
                            <a href="/saml/logout">Logout</a>
                        </span>
                        </div>}
                        </div>
                        {!this.state.editMode && 
                        <p className="account-creation-n margin-top-large margin-bottom">
                        <strong>Account Creation</strong></p> }
                        {!this.state.editMode && 
                        <p >Please ensure to provide accurate information for your new account. The information you’ve provided will be used to pass Juniper’s compliance screening.
                        Required items are marked with a red asterisk (<span className="text-required">*</span>).
                        </p>}
                        {this.state.editMode && <p >To edit your account, complete the following form.
                        Required items are marked with a red asterisk (<span className="text-required">*</span>).
                        </p>}
                        <br />
                        <div className="message-container-warning">
                            <p>Juniper Networks User Registration System only accepts English characters. You will not be able to create an account using non-English characters (e.g. Asian languages and European characters).</p>
                            <p><span className="text-color-red">*Important Note*</span> Please ensure you provide accurate personal information to pass Juniper's compliance screening. The address and Zip/Postal Code need to be valid and in the correct format.</p>
                        </div>
                        {this.state.editMode && loggedInUserInfo && loggedInUserInfo !== null && (loggedInUserInfo.userType ==='Distributor' || 
                        loggedInUserInfo.userType ==='JPartner') &&
                            <EditAccountFieldsComponent userInfo={loggedInUserInfo}></EditAccountFieldsComponent>
                        }
                        <div className="padding-top" >
                            <div className="grid">
                                <div className="col-4">
                                    <p>
                                        <span className="label-container">Email Address</span>&nbsp;&nbsp;&nbsp;
                                    <span className="label-span">{loggedInUserInfo && loggedInUserInfo !== null ? loggedInUserInfo.userId : emailAddress}</span></p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">First Name <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='firstName' 
                                        value={this.state.firstName}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="100" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)}/>
                                        {this.state.errorfirstName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide First Name!</p>}
                                        {this.state.errorNonEngfirstName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}

                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Last Name <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='lastName' 
                                        value={this.state.lastName}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="100" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)}/>
                                        {this.state.errorlastName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Last Name!</p>}
                                        {this.state.errorNonEnglastName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                            {isLabUser &&
                                <div className="grid flex-horizontal col-4">
                                    <span className="label-container">Personal Use</span>&nbsp;&nbsp;&nbsp;
                                    <label className="container ">
                                        <input type="checkbox" value={this.state.isChecked} onClick={this.onCheckboxClick} 
                                        defaultChecked = {persistedPersonalUse} ></input>
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                            }
                            {((this.state.editMode && !isPartner) || !this.state.editMode) &&
                            <div className="grid">
                            <div className="col-6">
                                <p className="text-input-label">Company Name <span className="text-required">*</span> </p>
                                <div>
                                    <input type="text" placeholder="Type here..." name='companyName' 
                                    value={this.state.companyName}
                                     onChange={this.onChangeField} onBlur={this.checkforValidName} required disabled={this.state.isChecked || this.state.editMode || (!this.state.editMode && companyName !=="") || this.state.supplierMode} maxLength="125" />
                                    {this.state.errorcompanyName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Company Name!</p>}
                                    {this.state.errorNonEngcompanyName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    {this.state.errorInvalidcompanyName && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide a valid Company Name.</p>}
                                </div>
                            </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Company URL </p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='compUrl' 
                                        value={this.state.compUrl} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="100" disabled={this.state.editMode}/>
                                        {this.state.errorNonEngcompUrl && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                                </div>
                             }
                            
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Job Title</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='jobTitle' 
                                        value={this.state.jobTitle}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="50" />
                                        {this.state.errorNonEngjobTitle && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Job Function <span className="text-required">*</span></p>
                                    <div>
                                        <select className="no-margin-top select-height" onChange={this.onChangeField} 
                                        value={this.state.jobFunc}
                                         name="jobFunc" required>
                                            <option value="-1">- Please Specify -</option>
                                            {this.renderJobFunction(this.props.jobFunction)}
                                        </select>
                                        {this.state.errorjobFunc && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Job Function!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Phone Number <span className="text-required">*</span></p>
                                    <div>
                                        <div className="flex">
                                        {this.renderPhoneCode(phoneCode,countryCode)}
                                        <input type="text" placeholder="Type here..." name='phoneNum' 
                                        value={this.state.phoneNum}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" className="col-3"/>
                                        </div>
                                        {this.state.errorphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Phone Number!</p>}
                                        {this.state.errorNonEngphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                        {this.state.errorNonNumphoneNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Only Numeric characters allowed. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-2">
                                    <p className="text-input-label">Phone Extension</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='phoneExt' 
                                        value={this.state.phoneExt}
                                         onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" />
                                        {this.state.errorNonEngphoneExt && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-4">
                                    <p className="text-input-label">Fax Number</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='fax' 
                                        value={this.state.fax} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="20" />
                                        {this.state.errorNonEngfax && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Address 1 <span className="text-required">*</span></p>
                                    <div>
                                        {this.state.supplierMode}
                                        <input type="text" placeholder="Type here..." name='add1' 
                                        value={this.state.add1} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="40" disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.erroradd1 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Address 1!</p>}
                                        {this.state.errorNonEngadd1 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-6">
                                    <p className="text-input-label">Address 2</p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='add2' 
                                        value={this.state.add2}
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} maxLength="40" disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorNonEngadd2 && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">City <span className="text-required">*</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='city' 
                                        value={this.state.city} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="40" 
                                        onKeyPress={this.onKeyPressEvent.bind(this)} disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorcity && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide City!</p>}
                                        {this.state.errorNonEngcity && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-5">
                                    <p className="text-input-label">State/Province
                                    {states !== "No States Available" &&
                                    <span className ="text-required">*</span>
                                    }
                                    </p>
                                    <div>
                                        <select className="no-margin-top select-height" onChange={this.onChangeField} 
                                        value={this.state.stateProvince} 
                                        name="stateProvince" required = {states !== "No States Available" ? true : false}
                                        disabled={!this.editMode && this.state.supplierMode}>
                                            <option value="-1">{states === "No States Available" ? "- No States Available -"  : "- Please Specify -"}</option>
                                            {this.renderState(states)}
                                        </select>
                                        {this.state.errorstateProvince && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide State/Province!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                <div className="col-4">
                                    <p className="text-input-label">Zip/Postal Code <span className="text-required">*</span>
                                    <span className="padding-top-smaller padding-left-smaller text-hint">(Type N/A if not applicable)</span></p>
                                    <div>
                                        <input type="text" placeholder="Type here..." name='zip' 
                                        value={this.state.zip} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required maxLength="10" 
                                        disabled={!this.editMode && this.state.supplierMode}/>
                                        {this.state.errorzip && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Zip/Postal Code!</p>}
                                        {this.state.errorNonEngzip && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                            </div>
                             {this.state.elevatedCircleCode && <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Elevate Auth Code </p>
                                    <div>
                                        <input 
                                            type="text" 
                                            value={this.state.elevatedCircleCode}
                                            disabled 
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="grid">
                                <div className="col-6">
                                    <p className="email-address-span"> <span className="email-address">Country</span>&nbsp;&nbsp;&nbsp; <span className="spiderman-webcrawler">
                                    {loggedInUserInfo !== null ? CountryName : this.state.country}</span></p>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="col-6">
                                    <p className="text-input-label">Time Zone <span className="text-required">*</span></p>
                                    <div>
                                        <select className="no-margin-top" onChange={this.onChangeField} 
                                        value={this.state.timeZone} 
                                        name="timeZone" required>
                                            <option value="-1">- Please Specify -</option>
                                            {this.renderTimeZone(timeZones)}
                                        </select>
                                        {this.state.errortimeZone && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Please provide Time Zone!</p>}
                                        {this.state.errorNonEngtimeZone && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>&nbsp; &nbsp; &nbsp;
                                {!this.state.editMode && authCode !== "" && 
                                <div className="col-6">
                                    <p className="text-input-label">Authorization Code</p>
                                    <div>
                                        <input type="text" name='authCode' 
                                        value={loggedInUserInfo !== null ? Authcode :authCode} 
                                        onChange={this.onChangeField} onBlur={this.checkForEngChar} required disabled />
                                        {this.state.errorNonEngauthCode && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
                                    </div>
                                </div>
                                }
                                {!this.state.editMode && serialNumber !== "" && 
                                <div className="col-6">
                                    <p className="text-input-label">Product Serial Number/Software Support Reference Number</p>
                                    <div>
                                        <input type="text" name='serialNum' value={serialNumber} required disabled/>
                                    </div>
                                </div>
                                }
                            </div>

                           {this.state.editMode &&<div>
                               <br></br>
                                <div className="horizontal-line-short">
                                    <div className="horizontal-line-short-45" style={{width: "50%"}}></div>
                                </div>
                                <p><strong> <a href={ELQ_EDIT_URL(document.location.origin.indexOf("sndbx") > -1)} target="_blank" rel="noopener noreferrer">Click here</a> to edit Marketing Preferences. </strong></p>
                                <br></br>
                           </div>}

                            {!this.state.editMode && <div>
                                <br></br>
                                <div className="horizontal-line-short">
                                    <div className="horizontal-line-short-45" style={{width: "55.5%"}}></div>
                                </div>
                                <EloquaForm onChange={this.onChangeEloqua} eloQua={eloQua}></EloquaForm>
                                </div>}

                            {
                                //supplier apps
                            }
                        {this.state.supplierMode && <div className="grid">
                                <div className="col-6">
                                    <p className="email-address-span"> 
                                    <span className="email-address">Supplier Apps</span>&nbsp;&nbsp;&nbsp; <span className="spiderman-webcrawler"></span>
                                    </p>
                                    <div style= {{paddingTop : '10px'}}>
                                    {this.renderSupplierApps(this.state.supplierAppsList)}
                                    </div>
                                </div> 
                            </div>}
                            {(parsedAcctSetUpObj !== undefined && !parsedAcctSetUpObj.isGuestUserReq) && 
                                    <div>
                                        <br></br>
                                        <div className="horizontal-line-short">
                                            <div className="horizontal-line-short-45" style={{ width: "55.5%" }}></div>
                                        </div>
                                        <br></br>
                                        <div className="grid flex-horizontal">
                                            <label className="container">
                                                <input type="checkbox" name="viewJplaAgreement" checked={this.state.isActivationAllowed === true ? 'checked' : ''}  disabled={this.state.isActivationAllowed === false} onChange={this.handleChecked} />
                                                <span className="checkmark"></span>
                                            </label>
                                            I Agree with the &nbsp; <a href onClick={this.handleModalOpen} ><span className="view-agreement sett-blue-font">Juniper Purchase License Terms & Conditions</span></a>
                                        </div>
                                        {this.state.modalOpen && <JPLADataAgreement data={this.props.jplaS3Agreement} modalOpen={this.state.modalOpen} handleModalOpen={this.handleModalOpen} handleUpdateJplaUserAttributr={this.handleUpdateJplaUserAttributr} activationFlag={this.state.isActivationAllowed} />}
                                    </div>
                            }

                            {!this.state.editMode && 
                            <div className="grid nav-buttons">
                                <div>
                                    <button onClick={() => this.navigateToAccountSetUp(emailAddress, country,userType)} className="primary"><span className="back">&lt; Back </span></button>
                                </div>&nbsp; &nbsp;
                                <div>

                                   <button onClick={() => this.checkForAccountMatch()} className={this.state.enableNextButton ? "primary" : "primary disabled"}><span className="back">Next &gt; </span></button>
                                   <BarLoader  width={"50%"} color={'#84B135'} loading={this.props.addLoader} />

                                </div>
                            </div>}
                            {this.state.editMode && 
                            <div className="grid nav-buttons">
                                 <button onClick={() => this.updateAcctInfo(loggedInUserInfo.country)} className={"primary"}><span className="back">Save</span></button>
                            </div>}
                        </div>
                        <FooterHyperlinksComponent history={this.props.history} />
                    </div>
                </div>
                </LoadingOverlay>
                <DisclaimerDomainDialog dialogName = "confirmation-dialog" accountTitle={"Edit Account Information Confirmation"} accountText={"Your account information has been updated successfully!"}
                openDialog = {this.props.acctInfoUpdated === 'Success' ? true : false} history = {this.props.history}closeDialog = {this.closeDialog.bind(this)}/>

                <DisclaimerDomainDialog dialogName = "compliance-dialog" accountText={<div><p><strong>Please ensure the data provided is accurate and has a valid postal address, as well as a matching email domain according to the company field.</strong></p> <p className="text-color-red"><strong>Inaccurate information will fail Juniper’s compliance screening, delaying access to Juniper resources.</strong></p></div>}
                openDialog = {this.state.openDialog} history = {this.props.history} closeDialog = {this.closeComplianceDialog.bind(this)} proceedDialog = {this.proceedDialog.bind(this)}/>
                {this.props.accountMatchResponse && this.props.accountMatchResponse.T_MATCHED_BPS && this.props.accountMatchResponse.T_MATCHED_BPS.length > 0 &&
                <DisclaimerDomainDialog dialogName = "address-dialog" address={this.props.accountMatchResponse.ADDRESS_DATA_OUT[0]}
                openDialog = {this.state.addressMatch} history = {this.props.history} closeAddressDialog = {this.closeAddressDialog.bind(this)}/>
                }
            </div>
        )
    }

}

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state) {
    return {
        jobFunction: state.accountcreationReducer.jobFunction,
        timeZones: state.accountcreationReducer.timeZones,
        states: state.accountcreationReducer.states,
        loggedInUserInfo : state.accountcreationReducer.loggedInUserInfo,
        acctInfoUpdated : state.accountcreationReducer.acctInfoUpdated,
        savingLoader : state.accountcreationReducer.savingLoader,
        supplierInfo : state.accountcreationReducer.supplierInfo,
        accountMatchResponse : state.accountcreationReducer.accountMatchResponse,
        addLoader : state.accountcreationReducer.addLoader,
        jplaS3Agreement: state.accountcreationReducer.jplaS3Agreement,
    }
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchJobFunction,
    fetchTimeZones,
    fetchStates,
    getLoggedInUserInfo,
    CleanAcctInfo,
    updateAcctInformation,
    clearSuccessResponse,
    getSupplierInfo,
    clearSupplierInfo,
    getAccountMatch,
    getJPLAS3Agreement,
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(AccountCreationComponent)
