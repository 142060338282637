export const initialState = {
  jobFunction: [],
  timeZones: [],
  states: [],
  loggedInUserInfo: null,
  acctInfoUpdated: '',
  savingLoader: false,
  loadingStarts: ""
}

export const labUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_JOB_FUNCTION":
      return Object.assign({}, state, {
        jobFunction: action.jobFunction
      });
    case "GET_TIME_ZONES":
      return {
        ...state,
        timeZones: action.timeZones
      }
    case "GET_STATES":
      return {
        ...state,
        states: action.states
      }
    case "GET_ACCOUNT_INFO":
      return {
        ...state,
        loggedInUserInfo: action.loggedInUserInfo
      }
    case "UPDATE_ACCT_INFO":
      return {
        ...state,
        acctInfoUpdated: action.acctInfoUpdated
      }
    case "LOADING_UPDATE_ACCT":
      return {
        ...state,
        savingLoader: action.savingLoader
      }
    case "CLEAR_SUCCESS_RES":
      return {
        ...state,
        acctInfoUpdated: ''
      }
    case "LOADING":
      return {
        ...state,
        loadingStarts: action.loadingStarts,
      }

    default:
      return state;
  }
}