import React from 'react'
import FooterHyperlinks from "./FooterHyperlinksComponent"
/*******************************************************
 * Screen - Account Submission
 * Description - This is the fourth step needed for User 
 *               Registration.
 ******************************************************/

class AccountSubmissionComponent extends React.Component{


    isRejectedAccount = () => {
        const {state} = this.props.location
        if(state.userType !== "Base User" && state.isPublicUser === "True") {
            return true
        }

        return false
    }


    render(){
        let emailAddress = "";
        let serialNumRes = {};
        let errorMarketingPreference= "";
        let companyName = ""
        if(this.props.location.state !== undefined){
            emailAddress = this.props.location.state.userIdCreated
            serialNumRes = this.props.location.state.serialNumRes
            errorMarketingPreference = this.props.location.state.errorMarketingPreference
            companyName = this.props.location.state.companyName
        }

        return(
            <div className="body-container">
                <div className="grid padding-around font-size-medium center-element width-80">
		        <div className="col-12">

                {this.isRejectedAccount()? 
                (<div>
                    <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p>
                    <p className="font-size-large margin-top-large margin-bottom"><strong>Account Information Rejected</strong></p>
                    <p className="margin-bottom-large">Juniper Networks is unable to approve your user account registration for one of the following reasons:</p>

                    <ul>
                       <li>
                           <span> You submitted an email address with a public email domain, or</span>
                       </li>
                       <li> 
                           <span> There was a mismatch between your company name and your company email domain. </span>
                       </li>
                   </ul>
                   <p>Per Juniper policy, you must register using your company email address. You may not use public email domains to register a user account.</p>
                   
                   <br></br>

                   <p>Submitted email address: <strong>{emailAddress}</strong></p>
                   <p>Submitted company name: <strong>{companyName}</strong></p>

                   <br></br>


                   <p>If you believe this message is in error, contact <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">customer care</a>.</p>

                   </div>) 
                : 
                (<div>
                    <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p>
                    <p className="font-size-large margin-top-large margin-bottom"><strong>Account Information Submitted</strong></p>
                    <p className="margin-bottom-large">Your Account information has been submitted to our registration system. Once approved by our compliance team an email 
                       message will be sent to the provided email address ({emailAddress}) containing instructions for completing 
                       the activation of your account.</p>
                   <p>Please note that this approval process may take up to 24 hours. If you don’t receive an email confirmation within the next 24 hrs or need an urgent expedition of your 
                       account approval please <a href="https://support.juniper.net/support/requesting-support/" target="_blank" rel="noopener noreferrer">contact customer care</a>.</p>
                   {serialNumRes && serialNumRes.OSDisplayName !== null && serialNumRes.OSDisplayName === "Junos" &&
                   <div className="message-container-warning">
                       <p><span className="text-color-red">Note*</span>&nbsp;Based on the provided Product SN/SSRN your user account will have <span className="text-uppercase"><strong>{serialNumRes.OSDisplayName}</strong></span> SW download privileges applied.</p>
                   </div>
                   }
                   {errorMarketingPreference &&  <div className="message-container-warning">
                       <p><span className="text-color-red">Note*</span>&nbsp;<span> {errorMarketingPreference}</span></p>
                   </div>}
                   <div className="margin-top-large">
                   <p className="font-size-large margin-bottom-large"><strong>Looking Ahead</strong></p>
                   <p>You may preview what's available for…</p>
                   <ul>
                       <li>
                           <span>Customers on the Support page: Resources, services, and information for customers</span>
                       </li>
                       <li> 
                           <span>Partners on the Partner page: Programs, training, and technical information for partners</span>
                       </li>
                   </ul>
                   </div> 

                   </div>)
                }

                    <FooterHyperlinks history={this.props.history}/>
                </div>
                </div>
            </div>
        )
    }
}

export default AccountSubmissionComponent