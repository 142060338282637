import { ERROR_MARKETING_PREFERENCE } from "../actions/encryptionAgreementAction";

export const initialState = {
    userCreated : "",
    loadingStarts : null,
    loggedInUserInfo : null,
    acceptEncrypt : "",
    jplaS3Agreement: {}
  }
  
  export const CREATE_USER = 'CREATE_USER';
  
  export const encryptionAgreementReducer = (state = initialState,action) =>{
      switch (action.type) {
          case CREATE_USER:
          return {
            ...state,
            userCreated: action.userCreated
          }
          case "LOADING":
                return {
                    ...state,
                    loadingStarts : action.loadingStarts
                }
          case "GET_JPLA_S3":
                return {
                  ...state,
                  jplaS3Agreement : action.jplaS3Agreement
                }
          case "CLEAR_SUCCESS_MSG":
                return{
                  ...state,
                  userCreated : ""
                }
          case "GET_ACCOUNT_INFO" :
              return{
                ...state,
                loggedInUserInfo: action.loggedInUserInfo
              }
          case "ACCEPT_ENCRYPTION" :
          return {
            ...state,
            acceptEncrypt : action.acceptEncrypt
          }
          case "RESET_ACCEPT_ENCRYPTION" :
          return {
            ...state,
            acceptEncrypt : ""
          }
           case ERROR_MARKETING_PREFERENCE:
             return {
               ...state,
               errorMarketingPreference: action.data
             }
          default:
                return state;
    }
  }